import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import http from "../../http";
import {
  Box,
  Button,
  CircularProgress,
  Collapse,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Pagination from "../my-profile/Pagination";
import moment from "moment";
import {
  ArrowDownward,
  ArrowForwardIos,
  ArrowLeft,
  ArrowUpward,
  KeyboardArrowDown,
  SwapVert,
} from "@mui/icons-material";

export default function ReferralUsersLists() {
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [referralUsers, setReferralUsers] = React.useState([]);
  const [open, setOpen] = React.useState([]);
  const [totalPages, setTotalPages] = React.useState(1);
  const [totalCount, setTotalCount] = React.useState(0);
  const [searchValue, setSearchValue] = React.useState("");
  const [filterReferralUsers, setFilterReferralUsers] = React.useState([]);
  const [selectedColumn, setSelectedColumn] = React.useState("");
  const [filterValue, setFilterValue] = React.useState("");
  const [sort, setSort] = React.useState(null);
  const [isSearchLoading, setIsSearchLoading] = React.useState(false);

  const searchableColumns = [
    { label: "Owner Id", value: "owner_id" },
    { label: "Used By User Id", value: "used_by_user_id" },
  ];

  const toggleRow = (index) => {
    if (open.includes(index)) {
      setOpen(open.filter((i) => i !== index));
    } else {
      setOpen([...open, index]);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
    fetchReferralUsersData(1);
  };

  const handleSearch = async () => {
    if (selectedColumn && filterValue) {
      setIsSearchLoading(true);
      try {
        await fetchReferralUsersData(1, {
          column: selectedColumn,
          value: filterValue,
        });
      } catch (error) {
        console.error("Error during search:", error);
      } finally {
        setIsSearchLoading(false);
      }
    } else {
      alert("Please select a column and enter a value to filter.");
    }
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
    fetchReferralUsersData(newPage);
  };

  const fetchReferralUsersData = async (pageNumber = 1, filter = {}) => {
    try {
      let query = `/user/get-referral-users?page=${pageNumber}&limit=${rowsPerPage}`;

      if (filter.column && filter.value) {
        query += `&filterColumn=${filter.column}&filterValue=${filter.value}`;
      }

      const response = await http.get(query);
      const { data, pagination } = response.data;

      setReferralUsers(data);
      setTotalPages(pagination.totalPages);
      setTotalCount(pagination.totalCount);
    } catch (error) {
      console.log("Error fetching referral users data", error);
    }
  };

  const sortedReferralUsers = React.useMemo(() => {
    if (sort === "ascending") {
      return [...referralUsers].sort((a, b) =>
        a.owner_id > b.owner_id ? 1 : -1
      );
    } else if (sort === "descending") {
      return [...referralUsers].sort((a, b) =>
        a.owner_id < b.owner_id ? 1 : -1
      );
    } else {
      return filterReferralUsers;
    }
  }, [filterReferralUsers, sort]);

  React.useEffect(() => {
    fetchReferralUsersData(page);
  }, [page, rowsPerPage]);

  const filteredReferralUsers = React.useMemo(() => {
    return referralUsers?.filter((curr) => {
      if (!searchValue) return true;
      return curr?.owner_id == searchValue;
    });
  }, [referralUsers, searchValue]);

  React.useEffect(() => {
    setFilterReferralUsers([...filteredReferralUsers]);
  }, [filteredReferralUsers]);

  return (
    <Box
      sx={{
        m: "auto",
        p: 2,
        paddingTop: 5,
      }}
      component={Paper}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mt: 3,
          mb: 1,
        }}
      >
        <Typography
          sx={{ fontSize: "25px", marginBottom: "30px", fontWeight: "bold" }}
        >
          Referral Users Lists
        </Typography>
      </Box>

      <Box sx={{ display: "flex", alignItems: "center", gap: 2, mb: 3 }}>
        <Select
          value={selectedColumn}
          onChange={(e) => setSelectedColumn(e.target.value)}
          displayEmpty
          fullWidth
          sx={{
            border: "1px solid #ced4da",
            borderRadius: "4px",
            fontSize: "1rem",
            minWidth: "200px",
          }}
        >
          <MenuItem value="" disabled>
            Kategorie
          </MenuItem>
          {searchableColumns.map((col) => (
            <MenuItem key={col.value} value={col.value}>
              {col.label}
            </MenuItem>
          ))}
        </Select>

        <TextField
          value={filterValue}
          onChange={(e) => setFilterValue(e.target.value)}
          label="Bezeichnung eingeben"
          fullWidth
          sx={{
            borderRadius: "4px",
            fontSize: "1rem",
            minWidth: "200px",
          }}
        />

        <Button
          variant="contained"
          color="primary"
          onClick={handleSearch}
          disabled={isSearchLoading}
          sx={{
            padding: "10px 16px",
            textTransform: "none",
            ":hover": {
              backgroundColor: "#003366",
            },
            fontWeight: "bold",
            display: "flex",
            alignItems: "center",
          }}
        >
          {isSearchLoading ? (
            <CircularProgress size={24} sx={{ color: "white" }} />
          ) : (
            "Search"
          )}
        </Button>
      </Box>
      <Box
        sx={{ p: 2, display: "flex", justifyContent: "end" }}
        // component={Paper}
      >
        <Pagination
          page={page - 1}
          products={referralUsers}
          rowsPerPage={rowsPerPage}
          totalPages={totalPages}
          handleFirstPageButtonClick={() => setPage(1)}
          handleBackButtonClick={() => setPage(page - 1)}
          handleNextButtonClick={() => setPage(page + 1)}
          handleLastPageButtonClick={() => setPage(totalPages)}
          handleChangePage={handlePageChange}
        />
      </Box>
      <TableContainer>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <select
            style={{ padding: "6px", marginBottom: "6px" }}
            onChange={handleChangeRowsPerPage}
          >
            {[50, 100, 150, 200].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                zeigen {pageSize}
              </option>
            ))}
          </select>
          <input
            type="search"
            onChange={(event) => setSearchValue(event.target.value)}
            placeholder="Suche..."
            style={{ padding: "8px", marginBottom: "6px" }}
          />
        </div>

        <Table aria-label="a dense table">
          <TableHead sx={{ bgcolor: "#e4f0f1" }}>
            <TableRow>
              <TableCell align="left" sx={{ fontWeight: "600" }}>
                <Stack
                  onClick={() => {
                    if (sort === "ascending") {
                      setSort("descending");
                    } else if (sort === "descending") {
                      setSort(null);
                    } else {
                      setSort("ascending");
                    }
                  }}
                  sx={{ cursor: "pointer" }}
                  direction="row"
                  gap={1}
                >
                  Owner ID
                  {sort === "ascending" ? (
                    <ArrowUpward />
                  ) : sort === "descending" ? (
                    <ArrowDownward />
                  ) : (
                    <SwapVert />
                  )}
                </Stack>
              </TableCell>
              <TableCell align="left" sx={{ fontWeight: "600" }}>
                Used At
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedReferralUsers?.map((valued, index) => (
              <>
                <TableRow
                  key={index}
                  sx={{
                    cursor: "pointer",
                    background: "#F9F9F9",
                  }}
                  onClick={() => toggleRow(index)}
                >
                  <TableCell align="left">
                    <Stack direction="row" gap={1} alignItems="center">
                      <ArrowForwardIos
                        sx={
                          open.includes(index)
                            ? { transform: "rotate(90deg)", height: "14px" }
                            : { height: "14px" }
                        }
                      />
                      <Typography marginTop={0.25}>
                        {valued?.owner_id}
                      </Typography>
                    </Stack>
                  </TableCell>
                  <TableCell align="left">
                    {moment(valued?.used_at).format("YYYY-MM-DD HH:mm:ss")}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={2} sx={{ p: 0, borderBottom: "none" }}>
                    <Collapse
                      in={open.includes(index)}
                      timeout="auto"
                      unmountOnExit
                    >
                      <Table>
                        <TableHead sx={{ bgcolor: "#e4f0f1" }}>
                          <TableRow>
                            <TableCell align="left" sx={{ fontWeight: "600" }}>
                              ID
                            </TableCell>
                            <TableCell align="left" sx={{ fontWeight: "600" }}>
                              Referral Code
                            </TableCell>
                            <TableCell align="left" sx={{ fontWeight: "600" }}>
                              Used By User ID
                            </TableCell>
                            <TableCell align="left" sx={{ fontWeight: "600" }}>
                              Booking ID
                            </TableCell>
                            <TableCell align="left" sx={{ fontWeight: "600" }}>
                              Is Redeemed
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {valued?.referrals.map((referral, subIndex) => (
                            <TableRow
                              sx={{
                                background: "white",
                              }}
                              key={subIndex}
                            >
                              <TableCell align="left">{referral?.id}</TableCell>
                              <TableCell align="left">
                                {referral?.referral_code}
                              </TableCell>
                              <TableCell align="left">
                                {referral?.used_by_user_id}
                              </TableCell>
                              <TableCell align="left">
                                {referral?.booking_id}
                              </TableCell>
                              <TableCell align="left">
                                {referral?.is_redeemed === 1 ? "true" : "false"}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </Collapse>
                  </TableCell>
                </TableRow>
              </>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box
        sx={{ p: 2, display: "flex", justifyContent: "end" }}
        // component={Paper}
      >
        <Pagination
          page={page - 1}
          products={referralUsers}
          rowsPerPage={rowsPerPage}
          totalPages={totalPages}
          handleFirstPageButtonClick={() => setPage(1)}
          handleBackButtonClick={() => setPage(page - 1)}
          handleNextButtonClick={() => setPage(page + 1)}
          handleLastPageButtonClick={() => setPage(totalPages)}
          handleChangePage={handlePageChange}
        />
      </Box>
    </Box>
  );
}
