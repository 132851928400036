import {
  Document,
  Font,
  Image,
  Link,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import moment from "moment";
import 'moment/locale/de'

Font.register({
  family: "Open Sans",
  fonts: [
    {
      src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf",
    },
    {
      src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf",
      fontWeight: 600,
    },
    {
      src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-700.ttf",
      fontWeight: 700,
    },
    {
      src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-800.ttf",
      fontWeight: 800,
    },
  ],
});

const styles = StyleSheet.create({
  page: {
    fontFamily: "Open Sans",
    fontSize: 12,
  },
});

export const InvoiceDocument = ({ invoice }) => {
  moment.locale("de");
  const salesCommission =
    Number(invoice.amount) * Number(invoice.discount / 100);

  function formatNumber(value) {
    return new Intl.NumberFormat("de-DE", {
      style: "decimal",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(value);
  }

  return (
    <Document>
      <Page wrap={false} size="A4" style={styles.page}>
        <View style={{ paddingTop: 20, paddingRight: 50, paddingLeft: 50 }}>
          <View style={{ display: "flex", justifyContent: "flex-end" }}>
            <Image
              src={window.location.origin + "/images/logo.png"}
              style={{
                height: "100px",
                width: "100px",
                marginLeft: "auto",
                position: "relative",
                transform: "translateX(20px)",
              }}
            />
          </View>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "90%",
              marginRight: "auto",
              alignItems: "center",
            }}
          >
            <View
              style={{ display: "flex", flexDirection: "column", gap: "10px" }}
            >
              <Text style={{ fontSize: "8px" }}>
                Mirzoyan GbR • Kieler Straße 113 • 24119 Kronshagen
              </Text>
              <View style={{ display: "flex", flexDirection: "column" }}>
                <Text style={{ fontSize: "9px", textTransform: "capitalize" }}>
                  {invoice.firstName} {invoice.lastName}
                </Text>
                <Text style={{ fontSize: "9px", textTransform: "capitalize" }}>
                  {invoice.address}
                </Text>
                <Text style={{ fontSize: "9px", textTransform: "capitalize" }}>
                  {invoice.zipCode} {invoice.country}
                </Text>
              </View>
            </View>
            <View
              style={{ display: "flex", flexDirection: "column", gap: "20px" }}
            >
              <View style={{ display: "flex", flexDirection: "column" }}>
                <Text style={{ fontSize: "9px" }}>minmini</Text>
                <Text style={{ fontSize: "9px" }}>Mirzoyan GbR</Text>
                <Text style={{ fontSize: "9px" }}>Kieler Straße 113</Text>
                <Text style={{ fontSize: "9px" }}>24119 Kronshagen</Text>
                <Text style={{ fontSize: "9px" }}>Telefon: 0431- 59674213</Text>
                <Text style={{ fontSize: "9px" }}>E-Mail: info@minmini.de</Text>
              </View>
              <View style={{ display: "flex", flexDirection: "column" }}>
                <View
                  style={{ display: "flex", flexDirection: "row", gap: "40px" }}
                >
                  <Text style={{ fontSize: "9px", width: "50px" }}>
                    Kunden-ID:
                  </Text>
                  <Text style={{ fontSize: "9px" }}>{invoice.kundenId}</Text>
                </View>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "40px",
                  }}
                >
                  <Text style={{ fontSize: "9px", width: "50px" }}>Datum:</Text>
                  <Text style={{ fontSize: "9px" }}>
                    {moment(invoice.datum).format("DD. MMMM YYYY")}
                  </Text>
                </View>
              </View>
            </View>
          </View>

          <View
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: "15px",
              fontWeight: "bold",
            }}
          >
            <Text style={{ fontSize: "10px", fontWeight: "bold" }}>
              PayPal-Code: {Number(invoice.discount) !== 15 ? "- /" : ""}
              {Number(invoice.discount) !== 15 ? "Mietfrei" : ""}
              {invoice.paypalCode}
            </Text>
            <Text style={{ fontSize: "10px", fontWeight: "bold" }}>
              Rechnungsnummer: {invoice.rechnungsnummer}
            </Text>
            <Text style={{ fontSize: "10px", fontWeight: "bold" }}>
              Zahlungsart: Vorkasse
            </Text>
          </View>

          <View
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: "15px",
              gap: "15px",
            }}
          >
            <Text style={{ fontSize: "9px" }}>
              {invoice.title} {invoice.firstName},{" "}
            </Text>
            <Text style={{ fontSize: "9px" }}>
              während deiner Mietlaufzeit vom{" "}
              <Text style={{ textDecoration: "underline" }}>
                {moment(invoice.startDate).format("DD.MM.YYYY")} -{" "}
                {moment(invoice.endDate).format("DD.MM.YYYY")}
              </Text>{" "}
              hast du einen Gesamtumsatz von{" "}
              <Text style={{ textDecoration: "underline", fontWeight: "bold" }}>
                {formatNumber(invoice.amount)} €
              </Text>{" "}
              erzielt. Wir überweisen dir den Gesamtumsatz, abzüglich der
              vereinbarten Verkaufsprovision i.H.v. {invoice.discount} % auf die
              von dir genannte Bankverbindung.
            </Text>
            <Text style={{ fontSize: "9px" }}>
              Die Verkaufsprovision setzt sich wie folgt zusammen:{" "}
            </Text>
          </View>

          <View
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: "15px",
            }}
          >
            <View
              style={{
                display: "flex",
                alignItems: "center",
                paddingVertical: "4px",
                flexDirection: "row",
                borderBottom: "1px solid gray",
              }}
            >
              <Text style={{ fontSize: "9px", width: "15%" }}>Pos.</Text>
              <Text style={{ fontSize: "9px", width: "35%" }}>
                Beschreibung
              </Text>
              <Text style={{ fontSize: "9px", width: "35%" }}></Text>
              <Text style={{ fontSize: "9px", width: "15%" }}>Summe</Text>
            </View>
            <View
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
                paddingVertical: "4px",
                borderBottom: "1px solid gray",
              }}
            >
              <Text style={{ fontSize: "9px", width: "15%" }}>1</Text>
              <Text style={{ fontSize: "9px", width: "35%" }}>
                Verkaufsprovision
              </Text>
              <Text style={{ fontSize: "9px", width: "35%" }}></Text>
              <Text style={{ fontSize: "9px", width: "15%" }}>
                {formatNumber((salesCommission / 119) * 100)} €
              </Text>
            </View>
            <View
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
                paddingVertical: "4px",
                borderBottom: "1px solid gray",
              }}
            >
              <Text style={{ fontSize: "9px", width: "15%" }}></Text>
              <Text style={{ fontSize: "9px", width: "35%" }}></Text>
              <Text style={{ fontSize: "9px", width: "35%" }}>
                Zzgl. USt. 19%
              </Text>
              <Text style={{ fontSize: "9px", width: "15%" }}>
                {formatNumber(salesCommission - (salesCommission / 119) * 100)}{" "}
                €
              </Text>
            </View>
            <View
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
                paddingVertical: "4px",
                borderBottom: "1px solid gray",
              }}
            >
              <Text style={{ fontSize: "9px", width: "15%" }}></Text>
              <Text style={{ fontSize: "9px", width: "35%" }}></Text>
              <Text style={{ fontSize: "9px", width: "35%" }}>
                Rechnungsbetrag
              </Text>
              <Text style={{ fontSize: "9px", width: "15%" }}>
                {formatNumber(salesCommission)} €
              </Text>
            </View>
            <View
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
                paddingVertical: "10px",
              }}
            >
              <Text style={{ fontSize: "9px", width: "15%" }}></Text>
              <Text style={{ fontSize: "9px", width: "35%" }}></Text>
              <Text style={{ fontSize: "9px", width: "35%" }}></Text>
              <Text style={{ fontSize: "9px", width: "15%" }}></Text>
            </View>
            <View
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
                paddingVertical: "4px",
                borderTop: "1.5px solid black",
                borderBottom: "1.5px solid black",
              }}
            >
              <Text style={{ fontSize: "9px", width: "15%" }}></Text>
              <Text
                style={{ fontSize: "9px", fontWeight: "bold", width: "35%" }}
              >
                Auszahlungsbetrag
              </Text>
              <Text style={{ fontSize: "9px", width: "35%" }}></Text>
              <Text
                style={{ fontSize: "9px", fontWeight: "bold", width: "15%" }}
              >
                {formatNumber(Number(invoice.amount) - salesCommission)} €
              </Text>
            </View>
          </View>

          <View
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "15px",
              marginTop: "15px",
            }}
          >
            <Text style={{ fontSize: "9px" }}>
              Der Auszahlungsbetrag{" "}
              <Text style={{ fontWeight: "bold", textDecoration: "underline" }}>
                i.H.v. {formatNumber(Number(invoice.amount) - salesCommission)}{" "}
                €
              </Text>{" "}
              wird dir in den kommenden drei Werktagen überwiesen.
            </Text>
            <Text style={{ fontSize: "9px" }}>
              Vielen Dank für deine Buchung und bis bald!
            </Text>
            <Text style={{ fontSize: "9px" }}>Dein Team von minmini</Text>
            <Image
              src={window.location.origin + "/images/logo.png"}
              style={{ height: "60px", width: "60px" }}
            />
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
                justifyContent: "space-between",
                marginBottom: "30px",
                marginTop: "5px",
              }}
            >
              <View style={{ display: "flex", flexDirection: "column" }}>
                <Text style={{ fontSize: "7px" }}>Mirzoyan GbR</Text>
                <Text style={{ fontSize: "7px" }}>Geschäftsführer</Text>
                <Text style={{ fontSize: "7px" }}>
                  Christina, Mikael und Edgar Mirzoyan
                </Text>
                <Text style={{ fontSize: "7px" }}>Kieler Straße 113</Text>
                <Text style={{ fontSize: "7px" }}>24119 Kronshagen </Text>
              </View>
              <View style={{ display: "flex", flexDirection: "column" }}>
                <Text style={{ fontSize: "7px" }}>Bank: VR Bank Nord AG</Text>
                <Text style={{ fontSize: "7px" }}>
                  IBAN: DE56217635420001384198
                </Text>
                <Text style={{ fontSize: "7px" }}>USt-ID Nr. DE-361131804</Text>
                <Text style={{ fontSize: "7px" }}>Steuer-Nr. 20/283/97072</Text>
              </View>
              <View style={{ display: "flex", flexDirection: "column" }}>
                <Text style={{ fontSize: "7px" }}>E-Mail: info@minmini.de</Text>
                <Text style={{ fontSize: "7px" }}>
                  Internet: www.minmini.de
                </Text>
                <Text style={{ fontSize: "7px" }}>Telefon: 0431-59674213</Text>
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};
