import {
  KeyboardArrowLeft,
  KeyboardArrowRight,
  KeyboardDoubleArrowLeft,
  KeyboardDoubleArrowRight,
} from "@mui/icons-material";
import { Box, IconButton, TextField } from "@mui/material";
import React, { useState } from "react";

const Pagination = ({
  page,
  products,
  rowsPerPage,
  totalPages,
  handleFirstPageButtonClick,
  handleBackButtonClick,
  handleNextButtonClick,
  handleLastPageButtonClick,
  handleChangePage,
}) => {
  const [page1, setPage1] = useState(page);

  return (
    <Box
      sx={{
        display: "flex",
        gap: { xs: 0.8, sm: 1.3 },
        alignItems: "center",
        flexWrap: { sm: "nowrap" },
        justifyContent: { xs: "flex-end", sm: "flex-start" }, // Center items on small screens
      }}
    >
      <IconButton
        onClick={handleFirstPageButtonClick}
        sx={{
          "&:disabled": {
            backgroundColor: "#C0C0C0",
          },
          ":hover": {
            background: "#beafa0",
          },
          background: "#beafa0",
          p: { xs: "0.4rem", sm: "0.5rem" },
          height: { xs: "2rem", sm: "2.5rem" },
          width: { xs: "2rem", sm: "2.5rem" },
          borderRadius: ".5rem",
        }}
        disabled={page == 0}
        variant="contained"
      >
        <KeyboardDoubleArrowLeft
          sx={{ color: "white", fontSize: { xs: "1rem", sm: "inherit" } }}
        />
      </IconButton>

      <IconButton
        onClick={handleBackButtonClick}
        sx={{
          "&:disabled": {
            backgroundColor: "#C0C0C0",
          },
          ":hover": {
            background: "#beafa0",
          },
          background: "#beafa0",
          p: { xs: "0.4rem", sm: "0.5rem" },
          height: { xs: "2rem", sm: "2.5rem" },
          width: { xs: "2rem", sm: "2.5rem" },
          borderRadius: ".5rem",
        }}
        disabled={page == 0}
        variant="contained"
      >
        <KeyboardArrowLeft
          sx={{ color: "white", fontSize: { xs: "1rem", sm: "inherit" } }}
        />
      </IconButton>

      <Box
        sx={{
          whiteSpace: "nowrap",
          fontSize: { xs: "0.8rem", sm: "1rem" },
          textAlign: "center",
        }}
      >
        Page{" "}
        <strong>
          {page + 1} of {totalPages}
        </strong>
      </Box>

      <TextField
        type="number"
        value={page1 + 1}
        size="small"
        sx={{
          width: { xs: "3rem", sm: "4rem" },
          height: "2.5rem",
        }}
        onChange={(e) => {
          const newPage = Math.max(
            0,
            Math.min(totalPages - 1, e.target.value - 1)
          );
          setPage1(newPage);
          handleChangePage(e, newPage + 1);
        }}
        inputProps={{
          max: totalPages,
          min: 1,
        }}
      />

      <IconButton
        onClick={handleNextButtonClick}
        sx={{
          "&:disabled": {
            backgroundColor: "#C0C0C0",
          },
          ":hover": {
            background: "#beafa0",
          },
          background: "#beafa0",
          p: { xs: "0.4rem", sm: "0.5rem" },
          height: { xs: "2rem", sm: "2.5rem" },
          width: { xs: "2rem", sm: "2.5rem" },
          borderRadius: ".5rem",
        }}
        disabled={page >= totalPages - 1}
        variant="contained"
      >
        <KeyboardArrowRight
          sx={{ color: "white", fontSize: { xs: "1rem", sm: "inherit" } }}
        />
      </IconButton>

      <IconButton
        onClick={handleLastPageButtonClick}
        sx={{
          "&:disabled": {
            backgroundColor: "#C0C0C0",
          },
          ":hover": {
            background: "#beafa0",
          },
          background: "#beafa0",
          p: { xs: "0.4rem", sm: "0.5rem" },
          height: { xs: "2rem", sm: "2.5rem" },
          width: { xs: "2rem", sm: "2.5rem" },
          borderRadius: ".5rem",
        }}
        disabled={page >= totalPages - 1}
        variant="contained"
      >
        <KeyboardDoubleArrowRight
          sx={{ color: "white", fontSize: { xs: "1rem", sm: "inherit" } }}
        />
      </IconButton>
    </Box>
  );
};

export default Pagination;
