import FAQ from "./component/ContentPages/FAQ/FAQ";
import UberUns from "./component/ContentPages/UberUns";
import Datenschutz from "./component/ContentPages/Datenschutz";
import Letter from "./component/ContentPages/Letter";
import Impressum from "./component/ContentPages/Impressum";
import Urban from "./component/ContentPages/Urban";
import BookingTabs from "./component/bookingProcess/BookingTabs";
import Home from "./component/home/Home";
import Tabs from "./component/my-profile/Tabs";
import Login from "./component/my-profile/Login";
import AdminTabs from "./component/AdminProfile/AdminTabs";
import StandsDetails from "./component/AdminProfile/StandsDetails";
import StandsLists from "./component/AdminProfile/StandsLists";
import ProductLists from "./component/AdminProfile/ProductLists";
import UsersLists from "./component/AdminProfile/UsersLists";
import BookingsLists from "./component/AdminProfile/BookingsLists";
import CategoriesLists from "./component/AdminProfile/CategoriesLists";
import Coupons from "./component/AdminProfile/Coupons";
import Holidays from "./component/AdminProfile/Holidays";
import Promo from "./component/ContentPages/Promo";
import AddProduct from "./component/my-profile/AddProduct";
import UpdateProduct from "./component/my-profile/UpdateProduct";
import ExtendStands from "./component/my-profile/ExtendsStands/ExtendStands";
import ProfileAdmin from "./component/AdminProfile/Profile";
import Transactions from "./component/my-profile/ExtendsStands/ExtendStands";
import Bookings from "./component/my-profile/ExtendsStands/ExtendStands";
import Dashboard from "./component/my-profile/dahsboard";
import Products from "./component/my-profile/BasicTable";
import UserProfile from "./component/my-profile/Profile";
import VerkäufeTable from "./component/my-profile/VerkäufeTable";
import MeineBuchungTable from "./component/my-profile/MeineBuchungTable";
import ForgotPassword from "./component/my-profile/ForgotPassword";
import ApplyDiscount from "./component/my-profile/ApplyDiscount";
import ReferralUsersLists from "./component/AdminProfile/ReferralUsersLists";
import CouponDetail from "./component/my-profile/CouponDetail";
import InvoicesLists from "./component/AdminProfile/InvoicesLists";
import CollectionLists from "./component/AdminProfile/CollectionLists";

export const routes = [
  {
    key: "promo-route",
    title: "Promo",
    path: "/promo",
    enabled: true,
    component: Promo,
  },
  {
    key: "impressum",
    title: "Impressum",
    path: "/impressum",
    enabled: true,
    component: Impressum,
  },
  {
    key: "datenschutz",
    title: "Datenschutz",
    path: "/datenschutz",
    enabled: true,
    component: Datenschutz,
  },
  {
    key: "home-route",
    title: "Login",
    path: "/login",
    enabled: true,
    component: Login,
  },
  {
    key: "aut-route",
    title: "Auth",
    path: "/forgot-password",
    enabled: true,
    component: ForgotPassword,
  },
  {
    key: "Home",
    title: "Home",
    path: "/",
    enabled: true,
    component: Home,
  },

  //holidays
  //add_holiday
  {
    key: "about-route",
    title: "Home",
    path: "/booking-process",
    enabled: true,
    component: BookingTabs,
  },
  {
    key: "faq-route",
    title: "Home",
    path: "/faq",
    enabled: true,
    component: FAQ,
  },
  {
    key: "uberuns-route",
    title: "Über uns",
    path: "/uber-uns",
    enabled: true,
    component: UberUns,
  },

  {
    key: "urban-route",
    title: "Home",
    path: "/urban",
    enabled: true,
    component: Urban,
  },
  {
    key: "letter-route",
    title: "Home",
    path: "/letter",
    enabled: true,
    component: Letter,
  },
];

export const adminProfileRoutes = [
  {
    key: "stands-details",
    title: "admin-profile",
    path: "/stands-details/:id",
    enabled: true,
    component: StandsDetails,
  },
  {
    key: "user-details",
    title: "user-profile",
    path: "/user-details",
    enabled: true,
    component: ProfileAdmin,
  },
  // {
  //   key: 'admin-profile',
  //   title: 'admin-profile',
  //   path: '/admin-profile',
  //   enabled: true,
  //   component: AdminTabs,
  // },
  {
    key: "stands-admin",
    title: "admin-profile",
    path: "/stands-admin",
    enabled: true,
    component: StandsLists,
  },
  {
    key: "products-admin",
    title: "admin-profile",
    path: "/products-admin",
    enabled: true,
    component: ProductLists,
  },
  {
    key: "users-admin",
    title: "admin-users",
    path: "/users-admin",
    enabled: true,
    component: UsersLists,
  },
  {
    key: "bookings-admin",
    title: "admin-profile",
    path: "/bookings-admin",
    enabled: true,
    component: BookingsLists,
  },
  {
    key: "categories-admin",
    title: "admin-profile",
    path: "/categories-admin",
    enabled: true,
    component: CategoriesLists,
  },
  {
    key: "coupons-admin",
    title: "admin-profile",
    path: "/coupons-admin",
    enabled: true,
    component: Coupons,
  },
  {
    key: "referral-users-admin",
    title: "admin-profile",
    path: "/referral-users-admin",
    enabled: true,
    component: ReferralUsersLists,
  },
  {
    key: "holidays-admin",
    title: "admin-profile",
    path: "/holidays-admin",
    enabled: true,
    component: Holidays,
  },
  {
    key: "invoices-admin",
    title: "admin-profile",
    path: "/invoices-admin",
    enabled: true,
    component: InvoicesLists,
  },
  {
    key: "collections-admin",
    title: "admin-profile",
    path: "/collections-admin",
    enabled: true,
    component: CollectionLists,
  },
  {
    key: "holidays-admin",
    title: "admin-profile",
    path: "/add_holiday",
    enabled: true,
    component: Holidays,
  },
];
export const userProfileRoutes = [
  {
    key: "dashboard",
    title: "user-dashboard",
    path: "/my-profile/dashboard",
    enabled: true,
    component: Dashboard,
  },
  {
    key: "coupon-details",
    title: "coupon-details",
    path: "/my-profile/couponDetails",
    enabled: true,
    component: CouponDetail,
  },
  {
    key: "products-route",
    title: "products",
    path: "/my-profile/products",
    enabled: true,
    component: Products,
  },
  {
    key: "profile-route",
    title: "profile",
    path: "/my-profile/profile",
    enabled: true,
    component: UserProfile,
  },
  {
    key: "transactions-route",
    title: "transactions",
    path: "/my-profile/transactions",
    enabled: true,
    component: VerkäufeTable,
  },
  {
    key: "bookings-route",
    title: "bookings",
    path: "/my-profile/bookings",
    enabled: true,
    component: MeineBuchungTable,
  },
  {
    key: "stands-route",
    title: "stands",
    path: "/my-profile/stands/:id",
    enabled: true,
    component: ExtendStands,
  },
  {
    key: "add-product",
    title: "AddProduct",
    path: "/add_products",
    enabled: true,
    component: AddProduct,
  },
  {
    key: "apply-discount",
    title: "ApplyDiscount",
    path: "/apply_discount",
    enabled: true,
    component: ApplyDiscount,
  },
  {
    key: "update-product",
    title: "UpdateProduct",
    path: "/update_products/:id",
    enabled: true,
    component: UpdateProduct,
  },
];
