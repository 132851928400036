import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import http from "../../http";
import {
  Box,
  Button,
  CircularProgress,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import Pagination from "../my-profile/Pagination";

export default function ProductsList() {
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [products, setProducts] = React.useState([]);
  const [totalPages, setTotalPages] = React.useState(1);
  const [totalCount, setTotalCount] = React.useState(0);
  const [searchValue, setSearchValue] = React.useState("");
  const [filterProducts, setFilterProducts] = React.useState([]);

  const [isSearchLoading, setIsSearchLoading] = React.useState(false);
  const [selectedColumn, setSelectedColumn] = React.useState("");
  const [filterValue, setFilterValue] = React.useState("");

  const searchableColumns = [
    { label: "Product Id", value: "id" },
    { label: "Product SKU", value: "sku" },
    { label: "Brand Name", value: "brand_name" },
    { label: "Title", value: "title" },
    { label: "User Id", value: "user_id" },
  ];

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
    fetchProductsData(1);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
    fetchProductsData(newPage);
  };

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(
      event,
      Math.max(0, Math.ceil(products?.length / rowsPerPage) - 1)
    );
  };

  const handleSearch = async () => {
    if (selectedColumn && filterValue) {
      setIsSearchLoading(true);
      try {
        await fetchProductsData(1, {
          column: selectedColumn,
          value: filterValue,
        });
      } catch (error) {
        console.error("Error during search:", error);
      } finally {
        setIsSearchLoading(false);
      }
    } else {
      alert("Please select a column and enter a value to filter.");
    }
  };

  const fetchProductsData = async (pageNumber = 1, filter = {}) => {
    try {
      let query = `/product/get/all?page=${pageNumber}&limit=${rowsPerPage}`;

      if (filter.column && filter.value) {
        query += `&filterColumn=${filter.column}&filterValue=${filter.value}`;
      }

      const response = await http.get(query);
      const { data, pagination } = response.data;

      setProducts(data);
      setTotalPages(pagination.totalPages);
      setTotalCount(pagination.totalCount);
    } catch (error) {
      console.log("Error fetching products data", error);
    }
  };

  React.useEffect(() => {
    fetchProductsData(page);
  }, [page, rowsPerPage]);

  const { filteredProducts } = React.useMemo(() => {
    return {
      filteredProducts: products.filter((curr) => {
        if (!searchValue) {
          return true;
        }

        const searchLowerCase = searchValue.toLowerCase();
        return curr?.sku?.toLowerCase().includes(searchLowerCase);
      }),
    };
  }, [products, searchValue]);

  React.useEffect(() => {
    setFilterProducts([...filteredProducts]);
  }, [filteredProducts]);

  return (
    <Box
      sx={{
        m: "auto",
        p: 2,
        paddingTop: 5,
      }}
      component={Paper}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mt: 3,
          mb: 1,
        }}
      >
        <Typography
          sx={{ fontSize: "25px", marginBottom: "30px", fontWeight: "bold" }}
        >
          Product Lists
        </Typography>
      </Box>

      <Typography
        variant="h6"
        sx={{
          fontSize: "1.25rem",
          fontWeight: "bold",
          mb: 1,
          color: "#333",
        }}
      >
        Erweiterte Suche
      </Typography>

      <Box sx={{ display: "flex", alignItems: "center", gap: 2, mb: 3 }}>
        <Select
          value={selectedColumn}
          onChange={(e) => setSelectedColumn(e.target.value)}
          displayEmpty
          fullWidth
          sx={{
            border: "1px solid #ced4da",
            borderRadius: "4px",
            fontSize: "1rem",
            minWidth: "200px",
          }}
        >
          <MenuItem value="" disabled>
            Kategorie
          </MenuItem>
          {searchableColumns.map((col) => (
            <MenuItem key={col.value} value={col.value}>
              {col.label}
            </MenuItem>
          ))}
        </Select>

        <TextField
          value={filterValue}
          onChange={(e) => setFilterValue(e.target.value)}
          label="Bezeichnung eingeben"
          fullWidth
          sx={{
            borderRadius: "4px",
            fontSize: "1rem",
            minWidth: "200px",
          }}
        />

        <Button
          variant="contained"
          color="primary"
          onClick={handleSearch}
          disabled={isSearchLoading}
          sx={{
            padding: "10px 16px",
            textTransform: "none",
            ":hover": {
              backgroundColor: "#003366",
            },
            fontWeight: "bold",
            display: "flex",
            alignItems: "center",
          }}
        >
          {isSearchLoading ? (
            <CircularProgress size={24} sx={{ color: "white" }} />
          ) : (
            "Search"
          )}
        </Button>
      </Box>
      <Box
        sx={{ p: 2, display: "flex", justifyContent: "end" }}
        // component={Paper}
      >
        <Pagination
          page={page - 1}
          products={products}
          rowsPerPage={rowsPerPage}
          totalPages={totalPages}
          handleFirstPageButtonClick={() => setPage(1)}
          handleBackButtonClick={() => setPage(page - 1)}
          handleNextButtonClick={() => setPage(page + 1)}
          handleLastPageButtonClick={() => setPage(totalPages)}
          handleChangePage={handlePageChange}
        />
      </Box>
      <TableContainer>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <select
            style={{ padding: "6px", marginBottom: "6px" }}
            onChange={handleChangeRowsPerPage}
          >
            {[50, 100, 150, 200].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                zeigen {pageSize}
              </option>
            ))}
          </select>
          <input
            type="search"
            onChange={(event) => setSearchValue(event.target.value)}
            placeholder="Suche..."
            style={{ padding: "8px", marginBottom: "6px" }}
          />
        </div>

        <Table aria-label="a dense table">
          <TableHead sx={{ bgcolor: "#e4f0f1" }}>
            <TableRow>
              <TableCell align="left" sx={{ fontWeight: "600", width: "0%" }}>
                SKU
              </TableCell>
              <TableCell align="left" sx={{ fontWeight: "600", width: "15%" }}>
                Brand Name
              </TableCell>
              <TableCell align="left" sx={{ fontWeight: "600", width: "0%" }}>
                Title
              </TableCell>
              <TableCell align="left" sx={{ fontWeight: "600", width: "0%" }}>
                Description
              </TableCell>
              <TableCell align="left" sx={{ fontWeight: "600", width: "0%" }}>
                Quantity
              </TableCell>
              <TableCell align="left" sx={{ fontWeight: "600", width: "0%" }}>
                Price
              </TableCell>
              <TableCell align="left" sx={{ fontWeight: "600", width: "0%" }}>
                User ID
              </TableCell>
              <TableCell align="left" sx={{ fontWeight: "600", width: "0%" }}>
                Booking Status
              </TableCell>
            </TableRow>
          </TableHead>
          {filterProducts?.map((product) => (
            <TableBody>
              <TableRow
                sx={{
                  background: "#F9F9F9 ",
                }}
              >
                <TableCell sx={{}}>{product?.sku} </TableCell>
                <TableCell align="left" sx={{}}>
                  {product?.brand_name}
                </TableCell>
                <TableCell align="left" sx={{}}>
                  {product?.title}
                </TableCell>
                <TableCell align="left" sx={{}}>
                  {product?.description}
                </TableCell>
                <TableCell align="left" sx={{}}>
                  {product?.quantity}
                </TableCell>
                <TableCell align="left" sx={{}}>
                  €{product?.price}
                </TableCell>
                <TableCell align="left" sx={{}}>
                  {product?.user_id}
                </TableCell>
                <TableCell align="left">
                  {product.booking
                    ? product.booking.status === false ||
                      new Date(product.booking.end) < new Date()
                      ? "Expired"
                      : "Active"
                    : "No Booking"}
                </TableCell>
              </TableRow>
            </TableBody>
          ))}
        </Table>
      </TableContainer>
      <Box
        sx={{ p: 2, display: "flex", justifyContent: "end" }}
        // component={Paper}
      >
        <Pagination
          page={page - 1}
          products={products}
          rowsPerPage={rowsPerPage}
          totalPages={totalPages}
          handleFirstPageButtonClick={() => setPage(1)}
          handleBackButtonClick={() => setPage(page - 1)}
          handleNextButtonClick={() => setPage(page + 1)}
          handleLastPageButtonClick={() => setPage(totalPages)}
          handleChangePage={handlePageChange}
        />
      </Box>
    </Box>
  );
}
