import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import http from "../../http";
import moment from "moment";

import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  MenuItem,
  Select,
  Snackbar,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Pagination from "../my-profile/Pagination";
import { Link } from "react-router-dom";

export default function UsersLists() {
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [users, setUsers] = React.useState([]);
  const [totalPages, setTotalPages] = React.useState(1);
  const [totalCount, setTotalCount] = React.useState(0);
  const [hasNewsOffer, setHasNewsOffer] = React.useState("");
  const [showSnackbar, setShowSnackbar] = React.useState({
    message: "",
    type: "error",
  });

  const [isSearchLoading, setIsSearchLoading] = React.useState(false);
  const [selectedColumn, setSelectedColumn] = React.useState("");
  const [filterValue, setFilterValue] = React.useState("");
  const [open, setOpen] = React.useState({
    visibility: false,
    id: null,
  });

  const handleClickOpen = (id) => {
    setOpen({ visibility: true, id });
  };

  const handleClose = () => {
    setOpen({ visibility: false, id: null });
  };

  const searchableColumns = [
    { label: "User Id", value: "id" },
    { label: "First Name", value: "first_name" },
    { label: "Last Name", value: "last_name" },
    { label: "Email", value: "email" },
  ];

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
    fetchUsersData(1);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
    fetchUsersData(newPage);
  };

  const handleSearch = async () => {
    if (selectedColumn && filterValue) {
      setIsSearchLoading(true);
      try {
        await fetchUsersData(1, {
          column: selectedColumn,
          value: filterValue,
        });
      } catch (error) {
        console.error("Error during search:", error);
      } finally {
        setIsSearchLoading(false);
      }
    } else {
      alert("Please select a column and enter a value to filter.");
    }
  };

  const fetchUsersData = async (pageNumber = 1, filter = {}) => {
    try {
      let query = `/user/get-users?page=${pageNumber}&limit=${rowsPerPage}`;

      if (filter.column && filter.value) {
        query += `&filterColumn=${filter.column}&filterValue=${filter.value}`;
      }

      const response = await http.get(query);
      const { data, pagination } = response.data;

      setUsers(data);
      setTotalPages(pagination.totalPages);
      setTotalCount(pagination.totalCount);
    } catch (error) {
      console.log("Error fetching users data", error);
    }
  };

  React.useEffect(() => {
    fetchUsersData(page);
  }, [page, rowsPerPage]);

  const filteredUsers = users.filter((user) =>
    hasNewsOffer === "yes"
      ? user.news_offer
      : hasNewsOffer === "no"
      ? !user.news_offer
      : true
  );

  const handlePasswordRevert = async (id) => {
    try {
      const response = await http.put(`/user/password-toggle/${id}`, {
        action: "revert",
      });
      if (response?.data?.status === 200) {
        setShowSnackbar({
          message: "Password Reverted Successfully!",
          type: "success",
        });
      }
    } catch (err) {
      setShowSnackbar({
        message: err?.response?.data?.message,
        type: "error",
      });
    }
  };

  return (
    <Box
      sx={{
        m: "auto",
        p: 2,
        paddingTop: 5,
      }}
      component={Paper}
    >
      <Typography
        sx={{ fontSize: "25px", marginBottom: "30px", fontWeight: "bold" }}
      >
        Users Lists
      </Typography>

      <Snackbar
        open={showSnackbar?.message !== ""}
        autoHideDuration={6000}
        onClose={() => setShowSnackbar({ message: "", type: "error" })}
      >
        <Alert
          onClose={() => setShowSnackbar({ message: "", type: "error" })}
          severity={showSnackbar?.type}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {showSnackbar?.message}
        </Alert>
      </Snackbar>

      <Dialog
        open={open.visibility}
        onClose={handleClose}
        PaperProps={{
          component: "form",
          onSubmit: async (event) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries(formData.entries());
            const password = formJson.password;
            try {
              const response = await http.put(
                `/user/password-toggle/${open.id}`,
                {
                  action: "update",
                  newPassword: password,
                }
              );

              if (response?.data?.status === 200) {
                setShowSnackbar({
                  message: "Password Updated Successfully!",
                  type: "success",
                });
              }
            } catch (err) {
              setShowSnackbar({
                message: err?.response?.data?.message,
                type: "error",
              });
            }
            handleClose();
          },
          sx: {
            width: { xs: "90%", sm: "80%", md: "50%" },
            padding: 2,
          },
        }}
      >
        <DialogTitle>Update Password</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter your new password here
          </DialogContentText>
          <TextField
            autoFocus
            required
            margin="dense"
            id="password"
            name="password"
            label="Password"
            type="text"
            fullWidth
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit">Update</Button>
        </DialogActions>
      </Dialog>

      <Typography
        variant="h6"
        sx={{
          fontSize: "1.25rem",
          fontWeight: "bold",
          mb: 1,
          color: "#333",
        }}
      >
        Erweiterte Suche
      </Typography>

      <Box sx={{ display: "flex", alignItems: "center", gap: 2, mb: 3 }}>
        <Select
          value={selectedColumn}
          onChange={(e) => setSelectedColumn(e.target.value)}
          displayEmpty
          fullWidth
          sx={{
            border: "1px solid #ced4da",
            borderRadius: "4px",
            fontSize: "1rem",
            minWidth: "200px",
          }}
        >
          <MenuItem value="" disabled>
            Kategorie
          </MenuItem>
          {searchableColumns.map((col) => (
            <MenuItem key={col.value} value={col.value}>
              {col.label}
            </MenuItem>
          ))}
        </Select>

        <TextField
          value={filterValue}
          onChange={(e) => setFilterValue(e.target.value)}
          label="Bezeichnung eingeben"
          fullWidth
          sx={{
            borderRadius: "4px",
            fontSize: "1rem",
            minWidth: "200px",
          }}
        />

        <Button
          variant="contained"
          color="primary"
          onClick={handleSearch}
          disabled={isSearchLoading}
          sx={{
            padding: "10px 16px",
            textTransform: "none",
            ":hover": {
              backgroundColor: "#003366",
            },
            fontWeight: "bold",
            display: "flex",
            alignItems: "center",
          }}
        >
          {isSearchLoading ? (
            <CircularProgress size={24} sx={{ color: "white" }} />
          ) : (
            "Search"
          )}
        </Button>
      </Box>
      <Box sx={{ p: 2, display: "flex", justifyContent: "end" }}>
        <Pagination
          page={page - 1}
          products={users}
          rowsPerPage={rowsPerPage}
          totalPages={totalPages}
          handleFirstPageButtonClick={() => setPage(1)}
          handleBackButtonClick={() => setPage(page - 1)}
          handleNextButtonClick={() => setPage(page + 1)}
          handleLastPageButtonClick={() => setPage(totalPages)}
          handleChangePage={handlePageChange}
        />
      </Box>
      <TableContainer>
        <FormControl
          sx={{
            width: {
              sm: "100%",
              xs: "100%",
            },
            marginBottom: "10px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <select
              style={{ padding: "6px", marginBottom: "4px", marginTop: "6px" }}
              onChange={handleChangeRowsPerPage}
            >
              {[50, 100, 150, 200].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  zeigen {pageSize}
                </option>
              ))}
              <option value={5000}>alles anzeigen</option>
            </select>

            <select
              style={{ padding: "6px", marginBottom: "4px", marginTop: "6px" }}
              value={hasNewsOffer}
              onChange={(event) => setHasNewsOffer(event.target.value)}
            >
              <option value="">Has News Offer?</option>
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </select>
          </div>
        </FormControl>

        <Table aria-label="a dense table">
          <TableHead sx={{ bgcolor: "#e4f0f1" }}>
            <TableRow>
              <TableCell align="left" sx={{ fontWeight: "600" }}>
                ID
              </TableCell>{" "}
              <TableCell align="left" sx={{ fontWeight: "600" }}>
                Name
              </TableCell>{" "}
              <TableCell align="left" sx={{ fontWeight: "600" }}>
                Email
              </TableCell>
              <TableCell align="left" sx={{ fontWeight: "600" }}>
                News Offer
              </TableCell>
              <TableCell align="left" sx={{ fontWeight: "600" }}>
                Wallet
              </TableCell>
              <TableCell align="left" sx={{ fontWeight: "600" }}>
                Date
              </TableCell>
              <TableCell align="left" sx={{ fontWeight: "600" }}>
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          {filteredUsers.map((user) => (
            <TableBody>
              <TableRow
                sx={{
                  background: "#F9F9F9 ",
                }}
              >
                <TableCell sx={{}}>{user?.id} </TableCell>
                <TableCell sx={{}}>
                  {user?.first_name} {user?.last_name}{" "}
                </TableCell>
                <TableCell align="left" sx={{}}>
                  {user?.email}
                </TableCell>
                <TableCell align="left" sx={{}}>
                  {user?.news_offer ? "Yes" : "No"}
                </TableCell>
                <TableCell align="left" sx={{}}>
                  {user?.wallet}
                </TableCell>
                <TableCell align="left" sx={{}}>
                  {moment(user.createdAt).format("DD-MM-YYYY")}
                </TableCell>
                <TableCell align="left" sx={{}}>
                  <Stack direction="row" gap={1}>
                    <Link to={`/bookings-admin?user_id=${user?.id}`}>
                      Bookings
                    </Link>
                    <Link to={`/user-details?user_id=${user?.id}`}>View</Link>
                    <Box
                      onClick={() => handleClickOpen(user?.id)}
                      sx={{
                        color: "#0000EE",
                        cursor: "pointer",
                        textDecoration: "underline",
                      }}
                    >
                      Update
                    </Box>
                    <Box
                      onClick={() => handlePasswordRevert(user?.id)}
                      sx={{
                        color: "#0000EE",
                        cursor: "pointer",
                        textDecoration: "underline",
                      }}
                    >
                      Revert
                    </Box>
                  </Stack>
                </TableCell>
              </TableRow>
            </TableBody>
          ))}
        </Table>
      </TableContainer>
      <Box
        sx={{ p: 2, display: "flex", justifyContent: "end" }}
        // component={Paper}
      >
        <Pagination
          page={page - 1}
          products={users}
          rowsPerPage={rowsPerPage}
          totalPages={totalPages}
          handleFirstPageButtonClick={() => setPage(1)}
          handleBackButtonClick={() => setPage(page - 1)}
          handleNextButtonClick={() => setPage(page + 1)}
          handleLastPageButtonClick={() => setPage(totalPages)}
          handleChangePage={handlePageChange}
        />
      </Box>
    </Box>
  );
}
