import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import http from "../../http";
import moment from "moment";

import {
  Box,
  Button,
  FormControl,
  MenuItem,
  Grid,
  InputLabel,
  Select,
  Typography,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Pagination from "../my-profile/Pagination";
import { useFormik } from "formik";
import * as Yup from "yup";

export default function Coupons() {
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [coupons, setCoupons] = React.useState([]);
  const [totalPages, setTotalPages] = React.useState(1);
  const [totalCount, setTotalCount] = React.useState(0);
  const [add, setAdd] = React.useState(false);
  const [update, setUpdate] = React.useState(false);
  const [name, setName] = React.useState("");
  const [users, setUsers] = React.useState([]);

  const handleClickOpen = () => {
    setAdd(true);
  };

  const handleClose = () => {
    setAdd(false);
  };

  const handleUpdateClose = () => {
    setUpdate(false);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
    fetchCouponsData(1);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
    fetchCouponsData(newPage);
  };

  const fetchCouponsData = async (pageNumber = 1) => {
    try {
      const response = await http.get(
        `/couponRoute/coupons?page=${pageNumber}&limit=${rowsPerPage}`
      );
      const { data, pagination } = response.data;

      setCoupons(data);
      setTotalPages(pagination.totalPages);
      setTotalCount(pagination.totalCount);
    } catch (error) {
      console.log("Error fetching coupons data", error);
    }
  };

  React.useEffect(() => {
    fetchCouponsData(page);
  }, [page, rowsPerPage]);

  React.useEffect(() => {
    const fetchUsers = async () => {
      try {
        let config = {
          method: "get",
          maxBodyLength: Infinity,
          url: `/user/get-all-users`,
          headers: {},
        };

        http
          .request(config)
          .then((response) => {
            setUsers(response?.data?.data);
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (error) {
        console.log("Error fetching users", error);
      }
    };

    fetchUsers();
  }, []);

  const addNewProduct = (bytes, decimals = 2) => {
    if (name === "" || name === null) {
      alert("Please enter a category name");
      return;
    }

    const User_id = localStorage.getItem("User_id");
    let data = JSON.stringify({
      name: name,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "/category",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    http
      .request(config)
      .then((response) => {
        alert("category is added successfully");
        window.location.reload();
      })
      .catch((error) => {
        alert("Failed to add category.Please try again");
        // window.location.reload();
      });
  };

  const updateProduct = (bytes, decimals = 2) => {
    const User_id = localStorage.getItem("User_id");
    let data = JSON.stringify({
      name: name,
    });

    let config = {
      method: "put",
      maxBodyLength: Infinity,
      url: `/category/${id}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    http
      .request(config)
      .then((response) => {
        alert("category is Updated successfully");
        window.location.reload();
      })
      .catch((error) => {
        alert("Failed to Updated category.Please try again");
        window.location.reload();
      });
  };

  const deleteProduct = (id) => {
    let config = {
      method: "delete",
      maxBodyLength: Infinity,
      url: `/couponRoute/${id}`,
      headers: {},
    };

    http
      .request(config)
      .then((response) => {
        alert("coupon is Deleted successfully");
        window.location.reload();
      })
      .catch((error) => {
        alert("Failed to Delete coupon.Please try again");
        window.location.reload();
      });
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: "",
      code: "",
      discount_type: "",
      discount_amount: "",
      usage_limit: "",
      redeem_count: "",
      active: true,
      expiry_date: "",
      user_id: "",
    },

    onSubmit: (values) => {
      let data = JSON.stringify({
        name: values.name,
        code: values.code,
        discount_type: values.discount_type,
        discount_amount: values.discount_amount,
        usage_limit: values.usage_limit,
        redeem_count: 0,
        active: true,
        expiry_date: values.expiry_date,
        user_id: values.user_id,
      });

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: "/couponRoute/create",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      http
        .request(config)
        .then((response) => {
          alert("Gutschein erfolgreich hinzugefügt", response);
          window.location.reload();
        })
        .catch((error) => {
          alert(
            "Der Gutschein kann nicht hinzugefügt werden. Bitte versuche es erneut."
          );
          // window.location.reload();
        });
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Gutschein ist erforderlich"),
      code: Yup.string().required("code ist erforderlich"),
      // manufacturer_name: Yup.string().required("Herstellername ist erforderlich"),
      discount_type: Yup.string().required("Rabatttyp ist erforderlich"),
      discount_amount: Yup.string().required("Rabattbetrag ist erforderlich"),
      usage_limit: Yup.string().required(
        "Nutzungsbeschränkung ist erforderlich"
      ),
      expiry_date: Yup.string().required("Verfallsdatum ist erforderlich"),
      // bookings: Yup.string().required("Buchungen sind erforderlich"),
      // description: Yup.string().required("Beschreibung ist erforderlich"),
    }),
  });

  return (
    <Box sx={{ pb: "2rem" }}>
      <Box sx={{ m: "auto", p: 2 }}>
        <Dialog open={add} onClose={handleClose}>
          <DialogTitle>Gutschein hinzufügen</DialogTitle>
          <form
            onSubmit={formik.handleSubmit}
            style={{
              padding: "10px 50px 50px 50px",
              borderRadius: "1rem",
            }}
          >
            <DialogContent>
              <DialogContentText>
                Bitte füllen Sie die folgenden Felder aus, um einen Gutschein
                hinzuzufügen
              </DialogContentText>

              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <InputLabel
                    sx={{ fontWeight: "700", fontSize: "20px" }}
                    shrink
                    htmlFor="Name"
                  >
                    Name*
                  </InputLabel>
                  <TextField
                    fullWidth
                    id="name"
                    name="name"
                    placeholder="20% Coupon"
                    size="small"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    sx={{
                      "& label": {
                        color: "black",
                      },
                      "& input": {
                        backgroundColor: "#fdfcf6",
                      },
                    }}
                    helperText={formik.touched.name && formik.errors.name}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputLabel
                    sx={{ fontWeight: "700", fontSize: "20px" }}
                    shrink
                    htmlFor="code"
                  >
                    Code*
                  </InputLabel>
                  <TextField
                    fullWidth
                    id="code"
                    name="code"
                    placeholder="OFF20"
                    size="small"
                    value={formik.values.code}
                    onChange={formik.handleChange}
                    error={formik.touched.code && Boolean(formik.errors.code)}
                    sx={{
                      "& label": {
                        color: "black",
                      },
                      "& input": {
                        backgroundColor: "#fdfcf6",
                      },
                    }}
                    helperText={formik.touched.code && formik.errors.code}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputLabel
                    sx={{ fontWeight: 700, fontSize: "20px" }}
                    shrink
                    htmlFor="stands"
                  >
                    Rabatttyp*
                  </InputLabel>
                  <Select
                    labelId="discount_type"
                    id="discount_type"
                    name="discount_type"
                    size="small"
                    value={formik.values.discount_type}
                    onChange={formik.handleChange}
                    label="Rabatttyp*"
                    style={{ minWidth: "100%" }}
                  >
                    <MenuItem value={"fixed"}>{"Fixed"}</MenuItem>
                    <MenuItem value={"percentage"}>{"Percentage"}</MenuItem>
                  </Select>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputLabel
                    sx={{ fontWeight: "700", fontSize: "20px" }}
                    shrink
                    htmlFor="Name"
                  >
                    Rabattbetrag*
                  </InputLabel>
                  <TextField
                    fullWidth
                    id="discount_amount"
                    name="discount_amount"
                    placeholder="20"
                    size="small"
                    value={formik.values.discount_amount}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.discount_amount &&
                      Boolean(formik.errors.discount_amount)
                    }
                    sx={{
                      "& label": {
                        color: "black",
                      },
                      "& input": {
                        backgroundColor: "#fdfcf6",
                      },
                    }}
                    helperText={
                      formik.touched.discount_amount &&
                      formik.errors.discount_amount
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputLabel
                    sx={{ fontWeight: "700", fontSize: "20px" }}
                    shrink
                    htmlFor="Name"
                  >
                    Nutzungsbeschränkung*
                  </InputLabel>
                  <TextField
                    fullWidth
                    id="usage_limit"
                    name="usage_limit"
                    placeholder="20"
                    size="small"
                    value={formik.values.usage_limit}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.usage_limit &&
                      Boolean(formik.errors.usage_limit)
                    }
                    sx={{
                      "& label": {
                        color: "black",
                      },
                      "& input": {
                        backgroundColor: "#fdfcf6",
                      },
                    }}
                    helperText={
                      formik.touched.usage_limit && formik.errors.usage_limit
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputLabel
                    sx={{ fontWeight: "700", fontSize: "20px" }}
                    shrink
                    htmlFor="Name"
                  >
                    Verfallsdatum*
                  </InputLabel>
                  <TextField
                    fullWidth
                    id="expiry_date"
                    name="expiry_date"
                    placeholder="20"
                    size="small"
                    value={formik.values.expiry_date}
                    onChange={formik.handleChange}
                    type="date"
                    error={
                      formik.touched.expiry_date &&
                      Boolean(formik.errors.expiry_date)
                    }
                    sx={{
                      "& label": {
                        color: "black",
                      },
                      "& input": {
                        backgroundColor: "#fdfcf6",
                      },
                    }}
                    helperText={
                      formik.touched.expiry_date && formik.errors.expiry_date
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputLabel
                    sx={{ fontWeight: "700", fontSize: "20px" }}
                    shrink
                    htmlFor="user-dropdown"
                  >
                    Benutzer
                  </InputLabel>
                  <Select
                    labelId="user-dropdown-label"
                    id="user-dropdown"
                    name="user_id"
                    size="small"
                    value={formik.values.user_id}
                    onChange={formik.handleChange}
                    label="Benutzer*"
                    displayEmpty
                    fullWidth
                    sx={{
                      "& .MuiSelect-select": {
                        backgroundColor: "#fdfcf6",
                      },
                    }}
                  >
                    {users.map((user) => (
                      <MenuItem key={user.id} value={user.id}>
                        {`User ID (${user.id})`} {user.first_name}{" "}
                        {user.last_name}{" "}
                      </MenuItem>
                    ))}
                  </Select>
                  {formik.touched.user_id && formik.errors.user_id && (
                    <Typography color="error" variant="caption">
                      {formik.errors.user_id}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button style={{ color: "red" }} onClick={handleClose}>
                Stornieren
              </Button>
              <Button type="submit">Speichern</Button>
            </DialogActions>
          </form>
        </Dialog>

        <Dialog open={update} onClose={handleUpdateClose}>
          <DialogTitle>Kategorie aktualisieren</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Bitte geben Sie Ihr Wunschdatum ein, um dies zu aktualisieren.
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Kategorie"
              type="text"
              value={name}
              onChange={(text) => setName(text?.target.value)}
              fullWidth
              variant="standard"
            />
          </DialogContent>
          <DialogActions>
            <Button style={{ color: "red" }} onClick={handleUpdateClose}>
              Stornieren
            </Button>
            <Button onClick={updateProduct}>Speichern</Button>
          </DialogActions>
        </Dialog>
        <Box component={Paper} sx={{ p: 2 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mt: 3,
              mb: 1,
            }}
          >
            <Typography sx={{ fontSize: "25px", fontWeight: "bolder" }}>
              Gutscheinliste
            </Typography>
            <Button
              onClick={handleClickOpen}
              sx={{
                ":hover": {
                  bgcolor: "#e4f0f1",
                  color: "black",
                },
                bgcolor: "#e4f0f1",
                color: "black",
                fontWeight: "bold",
                padding: "1rem",
              }}
            >
              Gutscheine hinzufügen
            </Button>
          </Box>
          <Box
            sx={{ p: 2, display: "flex", justifyContent: "end" }}
            // component={Paper}
          >
            <Pagination
              page={page - 1}
              products={coupons}
              rowsPerPage={rowsPerPage}
              totalPages={totalPages}
              handleFirstPageButtonClick={() => setPage(1)}
              handleBackButtonClick={() => setPage(page - 1)}
              handleNextButtonClick={() => setPage(page + 1)}
              handleLastPageButtonClick={() => setPage(totalPages)}
              handleChangePage={handlePageChange}
            />
          </Box>
          <TableContainer>
            <FormControl
              sx={{
                width: {
                  sm: "6rem",
                  xs: "100%",
                },
                marginBottom: "10px",
              }}
            >
              <select
                style={{
                  padding: "6px",
                  marginBottom: "4px",
                  marginTop: "6px",
                }}
                onChange={handleChangeRowsPerPage}
              >
                {[50, 100, 150, 200].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    zeigen {pageSize}
                  </option>
                ))}
              </select>
            </FormControl>

            <Table aria-label="a dense table">
              <TableHead sx={{ bgcolor: "#e4f0f1" }}>
                <TableRow>
                  <TableCell align="left" sx={{ fontWeight: "600" }}>
                    Gutscheinname
                  </TableCell>{" "}
                  <TableCell align="left" sx={{ fontWeight: "600" }}>
                    Code
                  </TableCell>
                  <TableCell align="left" sx={{ fontWeight: "600" }}>
                    Rabatttyp
                  </TableCell>
                  <TableCell align="left" sx={{ fontWeight: "600" }}>
                    Rabattbetrag
                  </TableCell>
                  <TableCell align="left" sx={{ fontWeight: "600" }}>
                    Nutzungsbeschränkung
                  </TableCell>
                  <TableCell align="left" sx={{ fontWeight: "600" }}>
                    Anzahl einlösen
                  </TableCell>
                  <TableCell align="left" sx={{ fontWeight: "600" }}>
                    Verfallsdatum
                  </TableCell>
                  <TableCell align="left" sx={{ fontWeight: "600" }}>
                    Benutzer
                  </TableCell>
                  <TableCell align="left" sx={{ fontWeight: "600" }}>
                    Details anzeigen
                  </TableCell>
                </TableRow>
              </TableHead>
              {coupons?.map((coupon) => (
                <TableBody>
                  <TableRow
                    sx={{
                      background: "#F9F9F9 ",
                    }}
                  >
                    <TableCell sx={{}}>{coupon?.name} </TableCell>
                    <TableCell align="left" sx={{}}>
                      {coupon?.code}
                    </TableCell>
                    <TableCell align="left" sx={{}}>
                      {coupon?.discount_type}
                    </TableCell>
                    <TableCell align="left" sx={{}}>
                      {coupon?.discount_amount}
                    </TableCell>
                    <TableCell align="left" sx={{}}>
                      {coupon?.usage_limit}
                    </TableCell>
                    <TableCell align="left" sx={{}}>
                      {coupon?.redeem_count}
                    </TableCell>
                    <TableCell align="left" sx={{}}>
                      {" "}
                      {moment(coupon.expiry_date).format("DD-MM-YYYY")}
                    </TableCell>
                    <TableCell align="left" sx={{}}>
                      {coupon?.user_id}
                    </TableCell>
                    <TableCell align="left" sx={{}}>
                      {" "}
                      {/* <Button
                        onClick={() => {
                          setUpdatedItemId(valued?.id);
                          setName(valued?.holiday_date);
                          setUpdate(true);
                        }}
                        style={{
                          backgroundColor: "#d0a667",
                          color: "white",
                          marginRight: "8px"
                        }}
                      >
                        Bearbeiten
                      </Button> */}
                      <Button
                        onClick={() => deleteProduct(coupon?.id)}
                        style={{
                          backgroundColor: "#b2102f",
                          color: "white",
                        }}
                      >
                        Löschen
                      </Button>
                    </TableCell>
                  </TableRow>
                </TableBody>
              ))}
            </Table>
          </TableContainer>

          <Box
            sx={{ p: 2, display: "flex", justifyContent: "end" }}
            // component={Paper}
          >
            <Pagination
              page={page - 1}
              products={coupons}
              rowsPerPage={rowsPerPage}
              totalPages={totalPages}
              handleFirstPageButtonClick={() => setPage(1)}
              handleBackButtonClick={() => setPage(page - 1)}
              handleNextButtonClick={() => setPage(page + 1)}
              handleLastPageButtonClick={() => setPage(totalPages)}
              handleChangePage={handlePageChange}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
