import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import http from '../../../http';
import moment from 'moment';
import CardPricing from './card-pricing';
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';
import { addDays } from 'date-fns';
import { styled } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import {
  Container,
  Typography,
  Button,
  Grid,
  Card,
  CardActions,
  CardContent,
} from '@mui/material';

const StyledTabs = styled((props) => <Tabs {...props} />)({
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  '& .MuiTabs-indicatorSpan': {
    width: '100%',
    backgroundColor: 'GrayText',
  },
});

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: 'none',
    fontWeight: 'bold',
    fontSize: theme.typography.pxToRem(18),
    marginRight: theme.spacing(1),
    color: 'GrayText',
    width: '32%',
    textAlign: 'start',
    backgroundColor: '##F8F3EB',
    borderRadius: 5,
    '&.Mui-selected': {
      color: '#000',
      backgroundColor: '#D4D6D2',
    },
  })
);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const Stands = () => {
  const [selectDay, setSelectDay] = useState([
    {
      id: 1,
      title: '1 Woche',
      description: 'Neque quis est',
      icon: 'bx-walk',
      cardColor: '#dcb9b7',
      price: '25',
      discountPrice: '20',
      duration: 7,
      isSelected: false,
      features: [{ title: '+ 15% Provision' }],
    },
    {
      id: 2,
      title: '2 Wochen',
      description: 'Quis autem iure',
      icon: 'bx-run',
      price: '40',
      discountPrice: '30',
      cardColor: '#d3c2b2',
      duration: 14,
      isSelected: false,
      features: [{ title: '+ 15% Provision' }],
    },
    {
      id: 3,
      title: '3 Wochen',
      description: 'Sed ut neque unde',
      icon: 'bx-cycling',
      price: '60',
      discountPrice: '50',
      cardColor: '#cac397',
      duration: 21,
      isSelected: false,
      features: [{ title: '+ 15% Provision' }],
    },
    {
      id: 4,
      title: '4 Wochen',
      description: 'Itaque earum hic',
      icon: 'bx-car',
      price: '70',
      discountPrice: '60',
      cardColor: '#c5bfb8',
      duration: 28,
      isSelected: false,
      features: [{ title: '+ 15% Provision' }],
    },
  ]);

  const [selectDayItem, setSelectDayItem] = useState(null);

  const [activeTab, setactiveTab] = useState(1);
  const [activeTabVartical, setoggleTabVertical] = useState(1);

  const [passedSteps, setPassedSteps] = useState([1]);
  const [latestBooking, setLatestBookings] = useState([]);

  const [selectedStand, setSelectedStand] = useState(null);

  const [orderID, setOrderID] = useState(false);
  const [orderConfirm, setOrderConfirm] = useState(false);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const [sevenDayOverlap, setSevenDayOverlap] = useState(0);
  const [fourteenDayOverlap, setFourteenDayOverlap] = useState(0);
  const [twentyOneDayOverlap, setTwentyOneDayOverlap] = useState(0);
  const [twentyEightDayOverlap, setTwentyEightDayOverlap] = useState(0);

  useEffect(() => {
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: '/booking/get-user-current-bookings?limit=10000&page=1',
    };
    http
      .request(config)
      .then((response) => {
        if (response.data.response.data)
          setLatestBookings(response.data.response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  function toggleTab(tab) {
    setLoading(true);
    if (activeTab !== tab) {
      var modifiedSteps = [...passedSteps, tab];
      if (tab >= 1 && tab <= 3) {
        setactiveTab(tab);
        setPassedSteps(modifiedSteps);
      }
      setTimeout(() => {
        setLoading(false);
        setLoading(false);
      }, 2000);
    }
  }

  const createOrder = (data, actions) => {
    return actions.order
      .create({
        purchase_units: [
          {
            description: 'Rebook stand ',
            amount: {
              currency_code: 'EUR',
              value: Number(selectDayItem?.price),
            },
          },
        ],
        // not needed if a shipping address is actually needed
        application_context: {
          shipping_preference: 'NO_SHIPPING',
        },
      })
      .then((orderID) => {
        setOrderID(orderID);
        return orderID;
      });
  };

  const onApprove = (data, actions) => {
    return actions.order.capture().then(function (details) {
      const { payer } = details;
      const paypalCode = details?.purchase_units?.[0]?.payments?.captures?.[0]?.id

      const bookingEndDateUTC = moment(selectedStand.end).utc();

      if (bookingEndDateUTC.clone().add(1, 'days').day() === 0) {
        bookingEndDateUTC.add(2, 'days');
      } else {
        bookingEndDateUTC.add(1, 'days');
      }

      const extendedEndDate = bookingEndDateUTC.add(
        selectDayItem?.duration,
        'days'
      );

      let bookingData = JSON.stringify({
        end: extendedEndDate.toISOString(),
        rent_price: selectDayItem?.price,
        paypal_code: paypalCode,
        title: selectDayItem?.title,
      });

      let config = {
        method: 'put',
        maxBodyLength: Infinity,
        url: `/booking/${selectedStand?.id}`,
        headers: {
          'Content-Type': 'application/json',
        },
        data: bookingData,
      };
      http
        .request(config)
        .then((response) => {
          setSuccess(true);
          setOrderConfirm(true);
        })
        .catch(function (error) {
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log(error.response.data);
            alert(error.response.data.message);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser
            // and an instance of http.ClientRequest in node.js
            console.log(error.request);
            alert(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
            alert(error.message);
          }
        });
    });
  };

  const getExtendFeasibility = () => {
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `/booking/get-extend-feasibility?standId=${selectedStand?.stand_id}&bookingEndDate=${selectedStand?.end}`,
    };
    http
      .request(config)
      .then((response) => {
        if (response.data.response.data) {
          setSevenDayOverlap(response.data.response.data.sevenDayOverlap);
          setFourteenDayOverlap(response.data.response.data.fourteenDayOverlap);
          setTwentyOneDayOverlap(
            response.data.response.data.twentyOneDayOverlap
          );
          setTwentyEightDayOverlap(
            response.data.response.data.twentyEightDayOverlap
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    if (value === 0) {
      getExtendFeasibility();
    }
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <Container sx={{ boxShadow: 1, backgroundColor: '#fff' }}>
      <Box sx={{ width: '100%', padding: '10px' }}>
        <StyledTabs
          value={value}
          onChange={handleChange}
          aria-label='styled tabs example'
        >
          <StyledTab
            label={
              <span style={{ display: 'flex', alignItems: 'center' }}>
                Aktuelle Buchung
              </span>
            }
          />
          <StyledTab
            disabled={!selectedStand}
            label={<span>Neue Buchung</span>}
          />
          <StyledTab disabled={!selectedStand} label={<span>Zahlung</span>} />
        </StyledTabs>

        <TabPanel value={value} index={0}>
          {latestBooking.length === 0 ? (
            <Typography variant='h6' sx={{ mt: 3, textAlign: 'center' }}>
              Keine aktuellen Buchungen
            </Typography>
          ) : (
            <Grid container spacing={3}>
              {latestBooking.map((booking, key) => (
                <Grid item xs={12} sm={4} key={'booking-' + key}>
                  <Card variant='outlined' sx={{ p: 2 }}>
                    <CardContent>
                      <Typography sx={{ mb: 2 }} variant='h4' component='div'>
                        {'Stand Detail'}{' '}
                      </Typography>
                      <Typography sx={{ mb: 1.5 }} color='text.secondary'>
                        {'Buchungsdauer:'}
                        {booking?.duration} Tage
                      </Typography>
                      <Typography sx={{ mb: 1.5 }} color='text.secondary'>
                        {'Mietbeginn:'}
                        {moment(new Date(booking?.start)).format('LL')}
                      </Typography>
                      <Typography sx={{ mb: 1.5 }} color='text.secondary'>
                        {'Mietende:'}
                        {moment(new Date(booking.end)).format('LL')}
                      </Typography>
                      <Typography sx={{ mb: 1.5 }} color='text.secondary'>
                        {'Regal-Numme:'}
                        {booking?.stand_id}
                      </Typography>
                      <Typography sx={{ mb: 1.5 }} color='text.secondary'>
                        {'Status:  '}
                        {booking?.status == 1 ? 'Aktive' : 'Inaktive'}
                      </Typography>
                    </CardContent>
                    <CardActions
                      sx={{ display: 'flex', justifyContent: 'center' }}
                    >
                      <Button
                        onClick={() => {
                          setSelectedStand(booking);
                        }}
                        size='large'
                        variant='contained'
                        sx={{
                          ':hover': {
                            bgcolor:
                              selectedStand?.id == booking.id
                                ? '#B39076'
                                : '#7B7E80',
                          },
                          width: '90%',
                          bgcolor:
                            selectedStand?.id == booking.id
                              ? '#B39076'
                              : '#7B7E80',
                        }}
                      >
                        {selectedStand?.id == booking.id
                          ? 'Ausgewählt'
                          : 'Jetzt Buchen'}
                      </Button>
                    </CardActions>
                  </Card>
                </Grid>
              ))}
            </Grid>
          )}
        </TabPanel>

        <TabPanel value={value} index={1}>
          <Typography variant='h6' sx={{ mt: 3, textAlign: 'center' }}>
            Die möglichen Buchungs-Optionen variieren je nach Verfügbarkeit des
            Regals
          </Typography>
          <Grid sx={{ mb: 3, mt: 3 }} container spacing={3}>
            {selectDay?.map((pricing, key) => (
              <CardPricing
                pricing={pricing}
                sevenDayOverlap={sevenDayOverlap}
                fourteenDayOverlap={fourteenDayOverlap}
                twentyOneDayOverlap={twentyOneDayOverlap}
                twentyEightDayOverlap={twentyEightDayOverlap}
                key={'_pricing_' + key}
                setSelectDay={(item) => {
                  const selectedDayClone = [...selectDay];
                  const newUpdatedList = selectedDayClone.map((day) => {
                    if (day.id === item?.id) {
                      day['isSelected'] = true;
                      return day;
                    } else {
                      day['isSelected'] = false;
                      return day;
                    }
                  });
                  setSelectDay(newUpdatedList);
                  setSelectDayItem(item);
                }}
              />
            ))}
          </Grid>
        </TabPanel>

        <TabPanel value={value} index={2}>
          <div style={{ justifyContent: 'center', marginTop: 20 }}>
            {orderConfirm ? (
              <div
                style={{ display: 'flex', margin: 5, justifyContent: 'center' }}
              >
                <div className='text-center'>
                  <div>
                    <h5 className='text-black'>
                      Deine Buchung wurde erfolgreich verlängert!
                    </h5>
                  </div>
                </div>
              </div>
            ) : (
              <PayPalScriptProvider
                options={{
                  'client-id':
                    'ARni4L2au2UrJiQ5MAhZ6x1DvU45y39H5absAGCtNNwdFdY412pOlCzWr8gB9ejNQym5LsiBC0WzTvP5',
                  'disable-funding': 'credit,paylater,card,giropay,sepa,credit',
                  currency: 'EUR',
                }}
              >
                {!loading ? (
                  <PayPalButtons
                    style={{ layout: 'vertical' }}
                    createOrder={createOrder}
                    onApprove={onApprove}
                  />
                ) : (
                  <span>Loading....</span>
                )}
              </PayPalScriptProvider>
            )}
          </div>
        </TabPanel>

        {value !== 3 && (
          <Box
            sx={{
              display: 'flex',
              gap: 3,
              alignItems: 'center',
              justifyContent: 'end',
              width: '100%',
            }}
          >
            <Button
              disabled={value === 0}
              variant='contained'
              sx={{ bgcolor: '#7B7E80' }}
              onClick={(e) => {
                handleChange(e, value - 1);
              }}
            >
              Zurück
            </Button>
            <Button
              disabled={value === 2 || !selectedStand}
              variant='contained'
              sx={{ bgcolor: '#D2A98B' }}
              onClick={(e) => {
                handleChange(e, value + 1);
              }}
            >
              Weiter
            </Button>
          </Box>
        )}
      </Box>
    </Container>
  );
};

export default Stands;
