import {
  Grid,
  TextField,
  Button,
  Box,
  FormControlLabel,
  Checkbox,
  InputLabel,
  Paper,
} from "@mui/material";
import { useFormik } from "formik";
import React, { useState, useEffect } from "react";
import http from "../../http";

const UpdateUserForm = ({ id }) => {
  const [Error, setError] = useState("");
  const [userData, setUserData] = useState(null);
  const handleUpdateProfile = (values) => {
    http
      .request({
        method: "put",
        maxBodyLength: Infinity,
        url: `/user/${id}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: values, // Pass the form values as the request payload
      })
      .then((response) => {
        // Handle the API response
        console.log(response);
        alert(response.data.message);
      })
      .catch(function (error) {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          alert(error?.response?.data?.message);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser
          // and an instance of http.ClientRequest in node.js
          console.log(error.request);
          alert(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
          alert(error.message);
        }
      });
  };

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await http.get(`/user/${id}`); // Replace with the appropriate API endpoint URL
        setUserData(response.data);
      } catch (error) {
        console.log("Error fetching user data", error);
      }
    };

    fetchUserData();
  }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      first_name: userData?.data?.first_name || "",
      last_name: userData?.data?.last_name || "",
      email: userData?.data?.email || "",
      iban: userData?.data?.iban || "",
      phone_number: userData?.data?.phone_number || "",
      address: userData?.data?.address || "",
      country: userData?.data?.country || "",
      zip: userData?.data?.zip,
      reference_code: userData?.data?.reference_code || "",
    },

    onSubmit: (values) => {
      handleUpdateProfile(values);
    },
  });

  if (!userData) {
    return <div>Loading...</div>;
  }
  return (
    <Paper
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "60vh",
        borderRadius: "1rem",
      }}
    >
      <form
        onSubmit={formik.handleSubmit}
        style={{
          backgroundColor: "#fff",
          padding: "50px",
          borderRadius: "1rem",
        }}
      >
        <Grid container spacing={3}>
          <Grid xs={12}>
            <Box sx={{ fontSize: "1.3rem", pb: "1rem" }}>
              Aktualisiere dein Profil
            </Box>
          </Grid>
          <Grid item xs={12} sm={3}>
            <InputLabel
              sx={{ fontWeight: "700", fontSize: "20px" }}
              shrink
              htmlFor="first_name"
            >
              Vorname
            </InputLabel>
            <TextField
              fullWidth
              id="first_name"
              name="first_name"
              placeholder="Vorname"
              value={formik.values.first_name}
              size="small"
              onChange={formik.handleChange}
              error={
                formik.touched.first_name && Boolean(formik.errors.first_name)
              }
              sx={{
                "& label": {
                  color: "black",
                },
                "& input": {
                  backgroundColor: "white",
                },
              }}
              helperText={formik.touched.first_name && formik.errors.first_name}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <InputLabel
              sx={{ fontWeight: 700, fontSize: "20px" }}
              shrink
              htmlFor="last_name"
            >
              Nachname
            </InputLabel>
            <TextField
              fullWidth
              id="last_name"
              name="last_name"
              // label="Last Name"
              placeholder="Nachname"
              size="small"
              value={formik.values.last_name}
              onChange={formik.handleChange}
              error={
                formik.touched.last_name && Boolean(formik.errors.last_name)
              }
              sx={{
                "& label": {
                  color: "black",
                },
                "& input": {
                  backgroundColor: "white",
                },
              }}
              helperText={formik.touched.last_name && formik.errors.last_name}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <InputLabel
              sx={{ fontWeight: 700, fontSize: "20px" }}
              shrink
              htmlFor="email"
            >
              Email
            </InputLabel>
            <TextField
              fullWidth
              id="email"
              size="small"
              name="email"
              placeholder="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              sx={{
                "& label": {
                  color: "black",
                },
                "& input": {
                  backgroundColor: "white",
                },
              }}
              helperText={formik.touched.email && formik.errors.email}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <InputLabel
              sx={{ fontWeight: 700, fontSize: "20px" }}
              shrink
              htmlFor="phone"
            >
              Telefonnummer
            </InputLabel>
            <TextField
              fullWidth
              id="phone"
              size="small"
              name="phone_number"
              placeholder="Telefonnummer"
              value={formik.values.phone_number}
              onChange={formik.handleChange}
              error={
                formik.touched.phone_number &&
                Boolean(formik.errors.phone_number)
              }
              sx={{
                "& label": {
                  color: "black",
                },
                "& input": {
                  backgroundColor: "white",
                },
              }}
              helperText={
                formik.touched.phone_number && formik.errors.phone_number
              }
            />
          </Grid>

          <Grid item xs={12} sm={3}>
            <InputLabel
              sx={{ fontWeight: 700, fontSize: "20px" }}
              shrink
              htmlFor="Iban"
            >
              IBAN
            </InputLabel>
            <TextField
              fullWidth
              id="iban"
              size="small"
              name="iban"
              placeholder="Iban"
              value={formik.values.iban}
              onChange={formik.handleChange}
              error={formik.touched.iban && Boolean(formik.errors.iban)}
              sx={{
                "& label": {
                  color: "black",
                },
                "& input": {
                  backgroundColor: "white",
                },
              }}
              helperText={formik.touched.iban && formik.errors.iban}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <InputLabel
              sx={{ fontWeight: 700, fontSize: "20px" }}
              shrink
              htmlFor="zip"
            >
              Postleitzahl
            </InputLabel>
            <TextField
              fullWidth
              id="zip"
              name="zip"
              placeholder="Postleitzahl"
              size="small"
              value={formik.values.zip}
              onChange={formik.handleChange}
              error={
                formik.touched.phone_number &&
                Boolean(formik.errors.phone_number)
              }
              sx={{
                "& label": {
                  color: "black",
                },
                "& input": {
                  backgroundColor: "white",
                },
              }}
              helperText={
                formik.touched.phone_number && formik.errors.phone_number
              }
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <InputLabel
              sx={{ fontWeight: 700, fontSize: "20px" }}
              shrink
              htmlFor="country"
            >
              Ort
            </InputLabel>
            <TextField
              fullWidth
              id="country"
              size="small"
              name="country"
              placeholder="Ort"
              value={formik.values.country}
              onChange={formik.handleChange}
              error={formik.touched.country && Boolean(formik.errors.country)}
              sx={{
                "& label": {
                  color: "black",
                },
                "& input": {
                  backgroundColor: "white",
                },
              }}
              helperText={formik.touched.country && formik.errors.country}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <InputLabel
              sx={{ fontWeight: 700, fontSize: "20px" }}
              shrink
              htmlFor="reference_code"
            >
              Referenzcode
            </InputLabel>
            <TextField
              fullWidth
              id="country"
              name="reference_code"
              placeholder="reference_code"
              disabled
              size="small"
              value={formik.values.reference_code}
              onChange={formik.handleChange}
              error={
                formik.touched.reference_code &&
                Boolean(formik.errors.reference_code)
              }
              sx={{
                "& label": {
                  color: "black",
                },
                "& input": {
                  backgroundColor: "white",
                },
              }}
              helperText={
                formik.touched.reference_code && formik.errors.reference_code
              }
            />
          </Grid>

          <Grid item xs={12}>
            <InputLabel
              sx={{ fontWeight: 700, fontSize: "20px" }}
              shrink
              htmlFor="address"
            >
              Adresse
            </InputLabel>
            <TextField
              fullWidth
              id="address"
              name="address"
              placeholder="Adresse"
              size="small"
              disabled
              value={formik.values.address}
              onChange={formik.handleChange}
              error={formik.touched.address && Boolean(formik.errors.address)}
              helperText={formik.touched.address && formik.errors.address}
              sx={{
                "& label": {
                  color: "black",
                },
                "& input": {
                  backgroundColor: "white",
                },
              }}
            />
          </Grid>
        </Grid>
        <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
          <Button
            type="submit"
            sx={{ textTransform: "capitalize" }}
            variant="contained"
            style={{ background: "#b89981" }}
            size="large"
          >
            Profil aktualisieren
          </Button>
        </Box>
      </form>
    </Paper>
  );
};
export default UpdateUserForm;
