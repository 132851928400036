import React from "react";
import styles from "./Spinner.module.css";

export const Spinner = ({ text }) => {
  return (
    <div className={styles["spinner-container"]}>
      <div className={styles.spinner}></div>
      <p className={styles.spinnerText}>{text}</p>
    </div>
  );
};
