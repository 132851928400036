import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { TooltipProvider } from '@radix-ui/react-tooltip';
import './styles/index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import SuspenseContent from './containers/SuspenseContent';
import { Toaster } from 'react-hot-toast';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <TooltipProvider>
      <Suspense fallback={<SuspenseContent />}>
        <Toaster
          position={'top-right'}
          toastOptions={{
            style: {
              margin: '15px',
              background: '#828282',
              color: '#fff',
              fontSize: '15px',
              width: '340px',
            },
            className: 'text-base',
            duration: 3000,
          }}
        />
        <App />
      </Suspense>
    </TooltipProvider>
  </React.StrictMode>
);

reportWebVitals();
