import { Box, CardContent, Grid, Stack, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import http from "../../http";
import StandsProductsDetails from "./StandsProductsDetails";
import { useParams } from "react-router-dom";
import { ArrowUpward } from "@mui/icons-material";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  height: "fit-content",
  // color: theme.palette.text.secondary,
}));
const StandsDetails = () => {
  const [active, setActive] = useState(0);
  const params = useParams();
  const [stand, setStand] = useState(null);
  const [showArrow, setShowArrow] = useState(false);
  const handleActive = (value) => {
    setActive(value);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setShowArrow(true);
      } else {
        setShowArrow(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        http
          .request({
            method: "get",
            maxBodyLength: Infinity,
            url: `/stand/${params?.id}`,
            headers: {},
          })
          .then((response) => {
            const { data } = response.data;
            setStand(data);
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (error) {
        console.log("Error fetching user data", error);
      }
    };

    fetchUserData();
  }, []);

  return (
    <Box sx={{ background: "#f8f8fb", py: "3rem" }}>
      <Box sx={{ m: "auto", p: 2 }}>
        <br />
        {showArrow && (
          <Stack
            border="1px solid #e1b6b4"
            bgcolor="#e1b6b4"
            borderRadius="100%"
            position="fixed"
            bottom={50}
            justifyContent="center"
            alignItems="center"
            padding={1}
            zIndex={100}
            width={50}
            height={50}
            sx={{ cursor: "pointer" }}
            onClick={scrollToTop}
            right={50}
          >
            <ArrowUpward sx={{ transform: "scale(1.5)", color: "white" }} />
          </Stack>
        )}
        <Box sx={{}}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h1
              style={{
                fontWeight: 700,
                fontSize: "20px",
                textTransform: "uppercase",
              }}
            >
              Stände Details
            </h1>
          </Box>
          <Box
            sx={{
              display: { xs: "block", sm: "flex" },
              justifyContent: "center",
              gap: "2rem",
              // background: "lightGray",
              margin: "auto",
              mt: 3,
            }}
          >
            <Item
              sx={{
                background: "white",
                // borderRadius: "2rem",
                minWidth: { xs: "100%", sm: "40%", md: "28%" },
                minHeight: "40vh",
                // border: "2px solid lightgray",
                p: 3,
              }}
            >
              <CardContent>
                <Typography
                  variant="body1"
                  component="div"
                  sx={{ fontWeight: 600, marginBottom: 2 }}
                >
                  Stand Nr. &nbsp;:&nbsp; {stand?.label}
                </Typography>
                <Typography
                  variant="body1"
                  component="div"
                  sx={{ fontWeight: 600, marginBottom: 2 }}
                >
                  Status &nbsp;:&nbsp;
                  {stand?.status == "active" ? "Aktiv" : "Deaktiviert"}
                </Typography>

                <Typography
                  variant="body1"
                  component="div"
                  sx={{ fontWeight: 600, marginBottom: 2 }}
                >
                  Verfügbarkeit:
                  {stand?.is_available == 1 ? "Verfügbar" : "Gebucht"}
                </Typography>

                <Typography
                  variant="body1"
                  component="div"
                  sx={{ fontWeight: 600, marginBottom: 2 }}
                >
                  Datum :{new Date(stand?.createdAt).toLocaleDateString()}
                </Typography>

                <Typography
                  variant="body1"
                  component="div"
                  sx={{ fontWeight: 600, marginBottom: 2 }}
                >
                  Breite : {stand?.width}
                </Typography>
                <Typography
                  variant="body1"
                  component="div"
                  sx={{ fontWeight: 600, marginBottom: 2 }}
                >
                  Höhe :{stand?.height}
                </Typography>
                <Typography
                  variant="body1"
                  component="div"
                  sx={{ fontWeight: 600, marginBottom: 2 }}
                >
                  X :{stand?.x}
                </Typography>
                <Typography
                  variant="body1"
                  component="div"
                  sx={{ fontWeight: 600, marginBottom: 2 }}
                >
                  Y :{stand?.y}
                </Typography>
                <Typography
                  variant="body1"
                  component="div"
                  sx={{ fontWeight: 600, marginBottom: 2 }}
                >
                  Messung :{stand?.measurement}
                </Typography>
              </CardContent>
            </Item>
            <Box
              sx={{
                background: "white",
                // borderRadius: "2rem",
                // border: "2px solid lightgray",
                width: { xs: "100%", sm: "100%", md: "100%" },
                minHeight: "20rem",
                paddingTop: 0,
                // flexDirection: "column",
                // textAlign: "left",
                // justifyContent: "center",
                mt: { xs: "1rem", sm: "0" },
              }}
            >
              <Grid
                container
                spacing={2}
                style={{ width: "100%", margin: "auto" }}
              >
                <Grid item xs={12} sm={12} md={12}>
                  <StandsProductsDetails />
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default StandsDetails;
