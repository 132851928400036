const bookKeepingFaqs = [
  {
    question:
      "What is bookkeeping, and how does Breeze Financials simplify the process for me?",
    answer:
      "Bookkeeping involves recording, organizing, and managing financial transactions for your business. Breeze Financials simplifies this by automating transaction categorization, reconciling accounts, and providing you with easy-to-understand financial reports so you can focus on growing your business.",
  },
  {
    question:
      "How does Breeze Financials ensure my financial data is accurate?",
    answer:
      "We combine advanced technology with human oversight. Automated tools handle data entry, while our financial specialists review transactions and reports to ensure accuracy.",
  },
  {
    question: "What types of businesses does Breeze Financials cater to?",
    answer:
      "Breeze Financials primarily supports small businesses, freelancers, and startups. Whether you’re in retail, consulting, or hospitality, we adapt our services to meet your needs.",
  },
  {
    question:
      "Can you explain the difference between bookkeeping and accounting?",
    answer:
      "Bookkeeping focuses on recording and organizing financial data. Accounting uses that data to analyze financial health, prepare taxes, and plan for the future. Breeze Financials specializes in bookkeeping but provides organized records that simplify accounting.",
  },
  {
    question: "How does Breeze Financials handle tax season preparation?",
    answer:
      "We ensure all transactions are categorized and reconciled throughout the year. When tax season arrives, your financial records are audit-ready, saving you time and stress.",
  },
  {
    question:
      "What is cash basis bookkeeping, and why is it suitable for small businesses?",
    answer:
      "Cash basis bookkeeping records transactions when money changes hands. It’s simpler and more suitable for small businesses because it reflects real-time cash flow.",
  },
  {
    question: "How does Breeze Financials track and categorize my expenses?",
    answer:
      "Our system automatically imports transactions from your accounts, assigns categories based on industry standards, and flags unusual items for review.",
  },
];

const servicesFaqs = [
  {
    question: "What does the Micro Bookkeeping ($220/month) package include?",
    answer:
      "This package covers basic transaction recording, receipt matching, account reconciliation, and a monthly financial snapshot tailored for businesses with lower transaction volumes.",
  },
  {
    question:
      "How often will I receive financial reports or updates from Breeze Financials?",
    answer:
      "Monthly. You’ll receive comprehensive reports summarizing your income, expenses, and overall financial health.",
  },
  {
    question:
      "Do you handle sales tax reconciliation and filing for my business?",
    answer:
      "Yes. We calculate, reconcile, but do not prepare your sales tax filings.",
  },
  {
    question: "What is included in the Clean-up/Catch-up service?",
    answer:
      "This service organizes and reconciles past transactions to bring your books up-to-date. We provide a detailed financial overview once completed.",
  },
  {
    question: "Can I schedule calls to review my financial reports?",
    answer:
      "Absolutely. We offer scheduled calls to answer questions, review reports, and provide insights into your finances.",
  },
  {
    question:
      "What is a financial snapshot, and how can it help me manage my business?",
    answer:
      "A financial snapshot is a simplified summary of your income, expenses, and cash flow. It provides a clear view of your financial position at a glance.",
  },
  {
    question:
      "How does Breeze Financials assist with reconciling my business accounts?",
    answer:
      "We compare your financial records against bank statements to ensure all transactions are accurately recorded and no errors are present.",
  },
];

const technologyFaqs = [
  {
    question:
      "What tools or software does Breeze Financials use for bookkeeping?",
    answer:
      "We use industry-standard software along with tools built in-house, as well as secure document-sharing tools.",
  },
  {
    question:
      "Will I need to install any software to use Breeze Financials’ services?",
    answer:
      "No. Our services are cloud-based. You can access your records through any web browser or mobile device.",
  },
  {
    question:
      "How does automation help streamline bookkeeping with Breeze Financials?",
    answer:
      "Automation handles repetitive tasks like importing transactions, categorizing expenses, and generating reports, saving you time and reducing errors.",
  },
  {
    question: "Can I access my bookkeeping records at any time?",
    answer:
      "Yes. You’ll have 24/7 access to your financial records through our secure platform.",
  },
  {
    question:
      "Does Breeze Financials integrate with payment platforms like Stripe and PayPal?",
    answer:
      "Yes. We integrate with popular platforms to ensure all transactions are seamlessly imported and recorded.",
  },
  {
    question:
      "How secure is my financial information on Breeze Financials’ platform?",
    answer:
      "We use strict data access protocols to keep your information safe.",
  },
];

const supportFaqs = [
  {
    question: "Can you customize your services to fit my business needs?",
    answer:
      "Yes. We work with you to create a personalized bookkeeping plan that aligns with your goals and industry requirements.",
  },
  {
    question:
      "Will I have a dedicated bookkeeper or point of contact at Breeze Financials?",
    answer:
      "Yes. You’ll have a dedicated specialist who understands your business and is available to assist.",
  },
  {
    question:
      "How does Breeze Financials tailor its services for different industries?",
    answer:
      "We customize expense categories, reporting formats, and service offerings to match industry-specific needs.",
  },
  {
    question:
      "Can you assist me with specific bookkeeping challenges unique to my business?",
    answer:
      "Of course! We love tackling unique challenges and finding solutions that work for you.",
  },
  {
    question:
      "Is support available if I have questions about my financial reports?",
    answer:
      "Yes. Our team is available to answer questions and help you interpret your financial data.",
  },
];

const pricingFaqs = [
  {
    question:
      "How is Breeze Financials more cost-effective than hiring an in-house bookkeeper?",
    answer:
      "We provide expert services at a fraction of the cost of an in-house employee, with no additional expenses like benefits or training.",
  },
  {
    question: "Are there any hidden fees in your pricing plans?",
    answer: "No. Our pricing is transparent, with no hidden costs.",
  },
  {
    question: "How does the free consultation work?",
    answer:
      "We discuss your needs, evaluate your current bookkeeping setup, and recommend the best solution for your business—at no cost.",
  },
  {
    question: "Can I upgrade or downgrade my plan as my business needs change?",
    answer: "Yes. You can adjust your plan anytime to fit your evolving needs.",
  },
  {
    question:
      "What kind of ROI can I expect from outsourcing my bookkeeping to Breeze Financials?",
    answer:
      "By saving time, reducing errors, and uncovering financial insights, our clients often see improved cash flow and reduced tax liabilities.",
  },
];

const onboardingFaqs = [
  {
    question: "How do I get started with Breeze Financials?",
    answer:
      "Simply book a consultation through our website. We’ll guide you through the onboarding process.",
  },
  {
    question:
      "What documents do I need to provide during the onboarding process?",
    answer:
      "Bank statements, receipts, invoices, and any existing financial records are helpful to get started.",
  },
  {
    question:
      "How long does it take to set up my bookkeeping with Breeze Financials?",
    answer:
      "Initial setup typically takes 1-2 weeks, depending on the complexity of your finances.",
  },
  {
    question:
      "Can you help me catch up on months or years of unorganized bookkeeping?",
    answer:
      "Yes. Our Clean-up/Catch-up service specializes in organizing past records and bringing them up-to-date.",
  },
  {
    question: "What happens during a Clean-up/Catch-up service consultation?",
    answer:
      "We assess your records, identify gaps, and create a plan to reconcile and organize all past transactions.",
  },
];

const legalFaqs = [
  {
    question:
      "Does Breeze Financials ensure compliance with local and federal tax laws?",
    answer:
      "Yes. We follow regulations to ensure your records are compliant and audit-ready.",
  },
  {
    question:
      "How do you handle my receipts and invoices to meet audit requirements?",
    answer:
      "We store and match receipts to transactions, ensuring all documentation is easily accessible if needed.",
  },
  {
    question:
      "What measures does Breeze Financials take to ensure my books are audit-ready?",
    answer:
      "We reconcile accounts monthly, document everything, and provide detailed reports to ensure full transparency.",
  },
  {
    question: "How do you stay updated on changes in tax laws and regulations?",
    answer:
      "Our team regularly reviews updates and participates in professional development to stay informed.",
  },
  {
    question: "Can you help with bookkeeping for international transactions?",
    answer:
      "Yes. We handle currency conversions and track international payments accurately.",
  },
];

const benefitsFaqs = [
  {
    question: "How does Breeze Financials help reduce stress around finances?",
    answer:
      "We take bookkeeping off your plate, so you can focus on your business without worrying about errors or deadlines.",
  },
  {
    question:
      "What are the long-term benefits of consistent bookkeeping with Breeze Financials?",
    answer:
      "You’ll gain financial clarity, stay tax-compliant, and have data-driven insights to grow your business.",
  },
  {
    question:
      "How can your services help improve the financial health of my business?",
    answer:
      "By identifying spending patterns, optimizing cash flow, and providing actionable insights, we help improve profitability.",
  },
  {
    question: "Can Breeze Financials save me money on taxes?",
    answer:
      "Yes. By accurately tracking expenses and maximizing deductions, we help minimize your tax liabilities.",
  },
  {
    question:
      "How does outsourcing bookkeeping to Breeze Financials give me more time to focus on my business?",
    answer:
      "We handle all the details, freeing you to focus on strategic growth and customer relationships.",
  },
];

const securityFaqs = [
  {
    question:
      "How does Breeze Financials safeguard my sensitive financial data?",
    answer:
      "We use encryption, secure servers, and limited access protocols to protect your information.",
  },
  {
    question: "Who has access to my financial information?",
    answer:
      "Only authorized Breeze Financials staff members directly involved with your account.",
  },
  {
    question:
      "Do you offer secure methods for sharing documents with Breeze Financials?",
    answer:
      "Yes. We use secure file-sharing platforms to ensure your data remains private.",
  },
  {
    question:
      "What happens to my data if I decide to stop using your services?",
    answer:
      "We provide you with all your financial records and securely delete them from our systems upon request.",
  },
  {
    question: "How do you ensure the privacy of my financial information?",
    answer:
      "We adhere to strict confidentiality agreements and data protection standards to keep your information private.",
  },
];

export default function Faqs() {
  return (
    <div className="bg-white">
      <h1 className="text-center py-6 sm:py-10 lg:py-14 mt-6 text-3xl font-semibold tracking-tight text-pretty text-gray-900 sm:text-4xl">
        Frequently asked questions
      </h1>
      <div className="mx-auto max-w-7xl px-6 py-6 sm:py-10 lg:px-8 lg:py-14 border-b border-gray-300">
        <div className="lg:grid lg:grid-cols-12 lg:gap-8">
          <div className="lg:col-span-5">
            <h2 className="text-xl font-semibold tracking-tight text-pretty text-gray-900 sm:text-2xl">
              General Bookkeeping Questions
            </h2>
          </div>
          <div className="mt-10 lg:col-span-7 lg:mt-0">
            <dl className="space-y-10">
              {bookKeepingFaqs.map((faq) => (
                <div key={faq.question}>
                  <dt className="text-base/7 font-semibold text-gray-900">
                    {faq.question}
                  </dt>
                  <dd className="mt-2 text-base/7 text-gray-600">
                    {faq.answer}
                  </dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
      </div>

      <div className="mx-auto max-w-7xl px-6 py-6 sm:py-10 lg:px-8 lg:py-14 border-b border-gray-300">
        <div className="lg:grid lg:grid-cols-12 lg:gap-8">
          <div className="lg:col-span-5">
            <h2 className="text-xl font-semibold tracking-tight text-pretty text-gray-900 sm:text-2xl">
              Services and Deliverables
            </h2>
          </div>
          <div className="mt-10 lg:col-span-7 lg:mt-0">
            <dl className="space-y-10">
              {servicesFaqs.map((faq) => (
                <div key={faq.question}>
                  <dt className="text-base/7 font-semibold text-gray-900">
                    {faq.question}
                  </dt>
                  <dd className="mt-2 text-base/7 text-gray-600">
                    {faq.answer}
                  </dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
      </div>

      <div className="mx-auto max-w-7xl px-6 py-6 sm:py-10 lg:px-8 lg:py-14 border-b border-gray-300">
        <div className="lg:grid lg:grid-cols-12 lg:gap-8">
          <div className="lg:col-span-5">
            <h2 className="text-xl font-semibold tracking-tight text-pretty text-gray-900 sm:text-2xl">
              Technology and Automation
            </h2>
          </div>
          <div className="mt-10 lg:col-span-7 lg:mt-0">
            <dl className="space-y-10">
              {technologyFaqs.map((faq) => (
                <div key={faq.question}>
                  <dt className="text-base/7 font-semibold text-gray-900">
                    {faq.question}
                  </dt>
                  <dd className="mt-2 text-base/7 text-gray-600">
                    {faq.answer}
                  </dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
      </div>

      <div className="mx-auto max-w-7xl px-6 py-6 sm:py-10 lg:px-8 lg:py-14 border-b border-gray-300">
        <div className="lg:grid lg:grid-cols-12 lg:gap-8">
          <div className="lg:col-span-5">
            <h2 className="text-xl font-semibold tracking-tight text-pretty text-gray-900 sm:text-2xl">
              Personalization and Support
            </h2>
          </div>
          <div className="mt-10 lg:col-span-7 lg:mt-0">
            <dl className="space-y-10">
              {supportFaqs.map((faq) => (
                <div key={faq.question}>
                  <dt className="text-base/7 font-semibold text-gray-900">
                    {faq.question}
                  </dt>
                  <dd className="mt-2 text-base/7 text-gray-600">
                    {faq.answer}
                  </dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
      </div>

      <div className="mx-auto max-w-7xl px-6 py-6 sm:py-10 lg:px-8 lg:py-14 border-b border-gray-300">
        <div className="lg:grid lg:grid-cols-12 lg:gap-8">
          <div className="lg:col-span-5">
            <h2 className="text-xl font-semibold tracking-tight text-pretty text-gray-900 sm:text-2xl">
              Pricing and Value
            </h2>
          </div>
          <div className="mt-10 lg:col-span-7 lg:mt-0">
            <dl className="space-y-10">
              {pricingFaqs.map((faq) => (
                <div key={faq.question}>
                  <dt className="text-base/7 font-semibold text-gray-900">
                    {faq.question}
                  </dt>
                  <dd className="mt-2 text-base/7 text-gray-600">
                    {faq.answer}
                  </dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
      </div>

      <div className="mx-auto max-w-7xl px-6 py-6 sm:py-10 lg:px-8 lg:py-14 border-b border-gray-300">
        <div className="lg:grid lg:grid-cols-12 lg:gap-8">
          <div className="lg:col-span-5">
            <h2 className="text-xl font-semibold tracking-tight text-pretty text-gray-900 sm:text-2xl">
              Process and Onboarding
            </h2>
          </div>
          <div className="mt-10 lg:col-span-7 lg:mt-0">
            <dl className="space-y-10">
              {onboardingFaqs.map((faq) => (
                <div key={faq.question}>
                  <dt className="text-base/7 font-semibold text-gray-900">
                    {faq.question}
                  </dt>
                  <dd className="mt-2 text-base/7 text-gray-600">
                    {faq.answer}
                  </dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
      </div>

      <div className="mx-auto max-w-7xl px-6 py-6 sm:py-10 lg:px-8 lg:py-14 border-b border-gray-300">
        <div className="lg:grid lg:grid-cols-12 lg:gap-8">
          <div className="lg:col-span-5">
            <h2 className="text-xl font-semibold tracking-tight text-pretty text-gray-900 sm:text-2xl">
              Compliance and Legal
            </h2>
          </div>
          <div className="mt-10 lg:col-span-7 lg:mt-0">
            <dl className="space-y-10">
              {legalFaqs.map((faq) => (
                <div key={faq.question}>
                  <dt className="text-base/7 font-semibold text-gray-900">
                    {faq.question}
                  </dt>
                  <dd className="mt-2 text-base/7 text-gray-600">
                    {faq.answer}
                  </dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
      </div>

      <div className="mx-auto max-w-7xl px-6 py-6 sm:py-10 lg:px-8 lg:py-14 border-b border-gray-300">
        <div className="lg:grid lg:grid-cols-12 lg:gap-8">
          <div className="lg:col-span-5">
            <h2 className="text-xl font-semibold tracking-tight text-pretty text-gray-900 sm:text-2xl">
              Benefits and Impact
            </h2>
          </div>
          <div className="mt-10 lg:col-span-7 lg:mt-0">
            <dl className="space-y-10">
              {benefitsFaqs.map((faq) => (
                <div key={faq.question}>
                  <dt className="text-base/7 font-semibold text-gray-900">
                    {faq.question}
                  </dt>
                  <dd className="mt-2 text-base/7 text-gray-600">
                    {faq.answer}
                  </dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
      </div>

      <div className="mx-auto max-w-7xl px-6 py-6 sm:py-10 lg:px-8 lg:py-14">
        <div className="lg:grid lg:grid-cols-12 lg:gap-8">
          <div className="lg:col-span-5">
            <h2 className="text-xl font-semibold tracking-tight text-pretty text-gray-900 sm:text-2xl">
              Security and Confidentiality
            </h2>
          </div>
          <div className="mt-10 lg:col-span-7 lg:mt-0">
            <dl className="space-y-10">
              {securityFaqs.map((faq) => (
                <div key={faq.question}>
                  <dt className="text-base/7 font-semibold text-gray-900">
                    {faq.question}
                  </dt>
                  <dd className="mt-2 text-base/7 text-gray-600">
                    {faq.answer}
                  </dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
      </div>
    </div>
  );
}
