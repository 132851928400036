import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import http from "../../http";
import moment from "moment";

import { Box, Button, FormControl, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Pagination from "../my-profile/Pagination";

export default function Holidays() {
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [holidays, setHolidays] = React.useState([]);
  const [totalPages, setTotalPages] = React.useState(1);
  const [totalCount, setTotalCount] = React.useState(0);
  const [age, setAge] = React.useState("");
  const [add, setAdd] = React.useState(false);
  const [update, setUpdate] = React.useState(false);
  const [name, setName] = React.useState(new Date());
  const [updatedItemId, setUpdatedItemId] = React.useState(null);

  const handleClickOpen = () => {
    setAdd(true);
  };

  const handleClose = () => {
    setAdd(false);
  };

  const handleUpdateClose = () => {
    setUpdate(false);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
    fetchHolidaysData(1);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
    fetchHolidaysData(newPage);
  };

  const fetchHolidaysData = async (pageNumber = 1) => {
    try {
      const response = await http.get(
        `/holidays?page=${pageNumber}&limit=${rowsPerPage}`
      );
      const { data, pagination } = response.data;

      setHolidays(data);
      setTotalPages(pagination.totalPages);
      setTotalCount(pagination.totalCount);
    } catch (error) {
      console.log("Error fetching holidays data", error);
    }
  };

  React.useEffect(() => {
    fetchHolidaysData(page);
  }, [page, rowsPerPage]);

  const addNewProduct = (bytes, decimals = 2) => {
    if (name === "" || name === null) {
      alert("Please enter a date");
      return;
    }

    const User_id = localStorage.getItem("User_id");
    let data = JSON.stringify({
      holiday_date: name,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "/holidays",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    http
      .request(config)
      .then((response) => {
        alert("holiday is added successfully");
        window.location.reload();
      })
      .catch((error) => {
        alert("Failed to add holiday.Please try again");
        // window.location.reload();
      });
  };

  const updateProduct = () => {
    const User_id = localStorage.getItem("User_id");
    let data = JSON.stringify({
      holiday_date: name,
    });

    let config = {
      method: "put",
      maxBodyLength: Infinity,
      url: `/holidays/${updatedItemId}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    http
      .request(config)
      .then((response) => {
        alert("holiday is Updated successfully");
        window.location.reload();
      })
      .catch((error) => {
        alert("Failed to Updated holiday.Please try again");
        window.location.reload();
      });
  };

  const deleteProduct = (id) => {
    let config = {
      method: "delete",
      maxBodyLength: Infinity,
      url: `/holidays/${id}`,
      headers: {},
    };

    http
      .request(config)
      .then((response) => {
        alert("holiday is Deleted successfully");
        window.location.reload();
      })
      .catch((error) => {
        alert("Failed to Delete holiday.Please try again");
        window.location.reload();
      });
  };

  return (
    <Box sx={{ background: "#f8f8fb", pb: "2rem" }}>
      <Box sx={{ m: "auto", p: 2 }}>
        <Box component={Paper} sx={{ p: 2 }}>
          <Dialog open={add} onClose={handleClose}>
            <DialogTitle>Add Holiday</DialogTitle>
            <DialogContent>
              <DialogContentText>
                To disable the particular, please enter your desire date here.
              </DialogContentText>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label="Holiday"
                type="date"
                value={name}
                onChange={(text) => setName(text?.target.value)}
                fullWidth
                variant="standard"
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button onClick={addNewProduct}>Save</Button>
            </DialogActions>
          </Dialog>

          <Dialog open={update} onClose={handleUpdateClose}>
            <DialogTitle>Update Holiday</DialogTitle>
            <DialogContent>
              <DialogContentText>
                please enter your desire date to update this.
              </DialogContentText>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label="Holiday"
                type="date"
                value={
                  name
                    ? new Date(name).toISOString().split("T")[0]
                    : new Date().toISOString().split("T")[0]
                }
                onChange={(text) => setName(text?.target.value)}
                fullWidth
                variant="standard"
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleUpdateClose}>Cancel</Button>
              <Button onClick={updateProduct}>Save</Button>
            </DialogActions>
          </Dialog>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mt: 3,
              mb: 1,
            }}
          >
            <Typography sx={{ fontSize: "25px", fontWeight: "bolder" }}>
              Holidays
            </Typography>
            <Button
              onClick={handleClickOpen}
              sx={{
                ":hover": {
                  bgcolor: "#e4f0f1",
                  color: "black",
                },
                bgcolor: "#e4f0f1",
                color: "black",
                fontWeight: "bold",
                padding: "1rem",
              }}
            >
              Feiertag hinzufügen
            </Button>
          </Box>
          <Box
            sx={{ p: 2, display: "flex", justifyContent: "end" }}
            // component={Paper}
          >
            <Pagination
              page={page - 1}
              products={holidays}
              rowsPerPage={rowsPerPage}
              totalPages={totalPages}
              handleFirstPageButtonClick={() => setPage(1)}
              handleBackButtonClick={() => setPage(page - 1)}
              handleNextButtonClick={() => setPage(page + 1)}
              handleLastPageButtonClick={() => setPage(totalPages)}
              handleChangePage={handlePageChange}
            />
          </Box>
          <TableContainer>
            <FormControl
              sx={{
                width: {
                  sm: "6rem",
                  xs: "100%",
                },
                marginBottom: "10px",
              }}
            >
              <select
                style={{
                  padding: "6px",
                  marginBottom: "4px",
                  marginTop: "6px",
                }}
                onChange={handleChangeRowsPerPage}
              >
                {[50, 100, 150, 200].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    zeigen {pageSize}
                  </option>
                ))}
              </select>
            </FormControl>

            <Table>
              <TableHead sx={{ bgcolor: "#e4f0f1" }}>
                <TableRow>
                  <TableCell align="left" sx={{ fontWeight: "600" }}>
                    Holiday
                  </TableCell>{" "}
                  <TableCell align="left" sx={{ fontWeight: "600" }}>
                    CreatedAt
                  </TableCell>
                  <TableCell align="left" sx={{ fontWeight: "600" }}>
                    View Details
                  </TableCell>
                </TableRow>
              </TableHead>
              {holidays?.map((valued) => (
                <TableBody>
                  <TableRow
                    sx={{
                      background: "#F9F9F9 ",
                    }}
                  >
                    <TableCell sx={{}}>
                      {moment(valued?.holiday_date).format("MM-DD-YYYY")}{" "}
                    </TableCell>

                    <TableCell align="left" sx={{}}>
                      {" "}
                      {moment(valued?.createdAt).format("MM-DD-YYYY")}
                    </TableCell>
                    <TableCell align="left" sx={{}}>
                      <Button
                        onClick={() => {
                          setUpdatedItemId(valued?.id);
                          setName(valued?.holiday_date);
                          setUpdate(true);
                        }}
                        style={{
                          backgroundColor: "#d0a667",
                          color: "white",
                          marginRight: "8px",
                        }}
                      >
                        Update
                      </Button>
                      <Button
                        onClick={() => deleteProduct(valued?.id)}
                        style={{
                          backgroundColor: "#b2102f",
                          color: "white",
                        }}
                      >
                        Delete
                      </Button>
                    </TableCell>
                  </TableRow>
                </TableBody>
              ))}
            </Table>
          </TableContainer>
          <Box
            sx={{ p: 2, display: "flex", justifyContent: "end" }}
            // component={Paper}
          >
            <Pagination
              page={page - 1}
              products={holidays}
              rowsPerPage={rowsPerPage}
              totalPages={totalPages}
              handleFirstPageButtonClick={() => setPage(1)}
              handleBackButtonClick={() => setPage(page - 1)}
              handleNextButtonClick={() => setPage(page + 1)}
              handleLastPageButtonClick={() => setPage(totalPages)}
              handleChangePage={handlePageChange}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
